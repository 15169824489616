import React from "react";

const SSGenExcel = [];

SSGenExcel['wbw'] = require('./wbw/SSGenExcel').default;
//SSGenExcel['wbpp'] = require('./wbpp/SSDownload');
//SSGenExcel['pop'] = require('./pop/SSDownload');
//SSGenExcel['ccoe'] = require('./ccoe/SSDownload');
SSGenExcel['opr'] = require('./opr/SSGenExcel').default;

export default SSGenExcel