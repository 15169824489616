export const milestonesLabel = {milestone1: '3 months', milestone2: '6 months', milestone3: '12 months'};
export const labels = {benefits: 'Benefits', additionalRecommendationsTableHeader: 'Additional Recommendations'};

export const sdps = [
    {value: "Amazon RDS",},
    {value: "AWS Database Migration Service",},
    {value: "Amazon DynamoDB",},
    {value: "Amazon EC2 for Windows Server",},
    {value: "AWS Server Migration Service",},
    {value: "Amazon CloudFront",},
    {value: "AWS Direct Connect",},
    {value: "AWS Lambda",},
    {value: "Amazon API Gateway",},
    {value: "Amazon EMR",},
    {value: "Amazon Kinesis",},
    {value: "Amazon Redshift",},
    {value: "Amazon QuickSight",},
    {value: "AWS CloudFormation",},
    {value: "AWS Service Catalog",},
    {value: "AWS Config",},
    {value: "AWS Systems Manager",},
    {value: "AWS IoT Core",},
    {value: "AWS IoT Analytics",},
    {value: "AWS IoT Greengrass",},
    {value: "Amazon Connect",},
    {value: "AWS WAF",},
    {value: "AWS GovCloud (US)",},
    {value: "AWS Transfer Family",},
];

export const competencies = [
    {value: "Education",},
    {value: "Energy",},
    {value: "Financial Services",},
    {value: "Government",},
    {value: "Health Care",},
    {value: "Industrial Software",},
    {value: "Life Sciences",},
    {value: "Nonprofit",},
    {value: "Public Safety and Disaster Response",},
    {value: "Retail",},
    {value: "Smart City",},
    {value: "Supply Chain",},
    {value: "Travel and Hospitality",},
    {value: "Cloud Management Tools",},
    {value: "Containers",},
    {value: "Data and Analytics",},
    {value: "DevOps",},
    {value: "Digital Customer Experience",},
    {value: "Digital Workplace",},
    {value: "High Performance Computing",},
    {value: "IoT",},
    {value: "Level 1 MSSP",},
    {value: "Machine Learning",},
    {value: "Mainframe Modernization",},
    {value: "Migration",},
    {value: "Migration and Modernization",},
    {value: "Networking",},
    {value: "Security",},
    {value: "Storage",},
    {value: "Microsoft Workloads",},
    {value: "Oracle",},
    {value: "SaaS",},
    {value: "SAP",},
];

export const programs = [
    {value: "Participate in AWS Well Architected Partner Program.",},
    {value: "Signup for Immersion day Partner.",},
    {value: "Marketplace Listings: XXXXXX",},
    {value: "Conduct 5 x Well Architected Partner Program.",},
    {value: "Run 3 Immersion days for Prospective customers.",},
    {value: "Certifications: Associate.",},
    {value: "Certifications: Professional and Speciality.",},
    {value: "Marketplace offering listings.",},
    {value: "Organize and run multi-customer Game Days.",},
    {value: "APN Ambassador Partner program.",},
];

export const benefits = [
    {value: "Company listing on AWS Partners page",},
    {value: "APN Badge & program logos",},
    {value: "Company listing on APN Blog welcome post",},
    {value: "AWS Partner Solutions Finder priority ranking",},
    {value: "Marketing Playbook and Toolkit.",},
    {value: "Marketing Development Funds (MDF) to drive demand generation and GTM activities - $5,000 per SDP, $10,000 per competency",},
    {value: "Deeper learning webinar series",},
    {value: "Advance bootcamps",},
    {value: "Discounted pricing and additional funding benefits.",},
];

export const additionalRecommendations = [
    {value: "Extend the reach of your customers and community by participating in APN Blogs .",},
    {value: "Take advantage of the AWS Migration Acceleration Program and its funding.",},
    {value: "Subscribe to the Software path if developing a custom software or building offerings using AWS services.",},
    {value: "Explore APN Innovation Sandbox credit to offset AWS usage costs incurred during solution development with AWS Promotional Credits as part of the Innovation Sandbox program.",},
];

export const disclaimerText = 'CONFIDENTIAL. © '+ (new Date().getFullYear()) +',  Amazon Web Services, Inc. or its Affiliates. This document is for discussion purposes only and does not create a legally binding or enforceable agreement or offer and is not enforceable against either party.  Neither party will be liable to the other party as a result of the failure to fulfill any obligation described in this document or the failure to enter into any agreement contemplated by this document.'