import React from "react";

let NavItems = {};

NavItems['wbw'] = require('./wbw/nav').items;
NavItems['wbpp'] = require('./wbpp/nav').items;
NavItems['pop'] = require('./pop/nav').items;
NavItems['ccoe'] = require('./ccoe/nav').items;
NavItems['opr'] = require('./opr/nav').items;

export default NavItems