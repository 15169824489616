export const OPRPreQ = {
  opr: {
    version: "1.1",
    preQ: [
      {
        component: "tab",
        _uid: "t1",
        label: "Business",
        fields: [
          {
            component: "field_group",
            _uid: "t1_s1",
            label: "Your Business",
            fields: [
              {
                component: "testarea",
                _uid: "t1_s1_q1",
                label: "Please share details about your organization?",
              },
              {
                component: "testarea",
                _uid: "t1_s1_q2",
                label: "Please share details about company (in numbers): employee count, annual revenue, locations, deal size and time to close?",
              },
              {
                component: "testarea",
                _uid: "t1_s1_q3",
                label: "Please share the makeup of your teams (number or percentages) - leadership, sales, developers, architects, delivery, PMO?",
              },
            ]
          },
          {
            component: "field_group",
            _uid: "t1_s2",
            label: "Your Customers",
            fields: [
              {
                component: "testarea",
                _uid: "t1_s2_q1",
                label: "Who are your typical customers? Do you have any reference customers?",
              },

            ]
          },
          {
            component: "field_group",
            _uid: "t1_s3",
            label: "AWS Expertise",
            fields: [
              {
                component: "testarea",
                _uid: "t1_s3_q1",
                label: "Please share a summary of your services and offerings? What are your specializations & niche?"
              },
              {
                component: "testarea",
                _uid: "t1_s3_q2",
                label: "Have you delivered projects and service on AWS? How many people are dedicated towards AWS and what are the certifications achieved by your team?"
              },
            ]
          },
          {
            component: "field_group",
            _uid: "t1_s4",
            label: "Future Vision",
            fields: [
              {
                component: "testarea",
                _uid: "t1_s4_q1",
                label: "Tell us about what brought AWS top of mind in 2022 as compared to previous years?"
              },
              {
                component: "testarea",
                _uid: "t1_s4_q2",
                label: "What are your key priorities for next 12 months? What are you trying to achieve from the AWS Partner Network?"
              },
            ]
          },
          {
            component: "field_group",
            _uid: "t1_s5",
            label: "Additional Details",
            fields: [
              {
                component: "testarea",
                _uid: "t1_s5_q1",
                label: "Are there any lingering questions you have after attending one of our weekly Partner Office Hours?"
              },
            ]
          },

        ]
      },
    ]
  }
};
