import React, { useEffect, useState } from "react";
import XLSX from "xlsx";

const SSGenExcel = (snapshot, sID) => {
  //PreQ
  let preQues = [];
  if (snapshot.preq !== undefined) {
    let preQ = Object.entries(snapshot.preq);
    preQ.map((item) => {
      preQues.push({
        Question: item[1].question,
        Answer: item[1].answer
      });
    })
  }

  // //SWOT
  // let swot = [];
  // if (snapshot.swot !== undefined) {
  //   snapshot.swot.strengths.map((item) => {
  //     swot.push({
  //       SWOT_Type: "Strength",
  //       Values: item.value
  //     })
  //   })
  //   snapshot.swot.weaknesses.map((item) => {
  //     swot.push({
  //       SWOT_Type: "Weakness",
  //       Values: item.value
  //     })
  //   })
  //   snapshot.swot.opportunities.map((item) => {
  //     swot.push({
  //       SWOT_Type: "Opportunity",
  //       Values: item.value
  //     })
  //   })
  //   snapshot.swot.threats.map((item) => {
  //     swot.push({
  //       SWOT_Type: "Threat",
  //       Values: item.value
  //     })
  //   })
  // }
  //
  // // UNI
  // let uni = [];
  // if (snapshot.uni !== undefined) {
  //   snapshot.uni.currentUniSPs.map((item) => {
  //     uni.push({Type: "Current Super Power", Super_Power: item.value})
  //   })
  //   snapshot.uni.futureUniSPs.map((item) => {
  //     uni.push({Type: "Future Super Power", Super_Power: item.value})
  //   })
  // }
  //
  // //Opps
  // let opps = [];
  // if (snapshot.opportunities !== undefined) {
  //   snapshot.opportunities.opportunities.map((item) => {
  //     opps.push({
  //       Customer: item.customer,
  //       Problem: item.problem,
  //       Idea: item.idea,
  //       Next_Steps: item.nextsteps
  //     });
  //   })
  // }
  //
  // //PR Stmt
  // let prstmt = [];
  // if (snapshot.prs !== undefined) {
  //   snapshot.prs.currentprstmt.map((item) => {
  //     prstmt.push({Type: "Current State", PR_Statements: item.value});
  //   })
  //   prstmt.push({Type: "Current Quote", PR_Statements: snapshot.prs.currentquote || ""});
  //
  //   snapshot.prs.futureprstmt.map((item) => {
  //     prstmt.push({Type: "Current State", PR_Statements: item.value});
  //   })
  //   prstmt.push({Type: "Current Quote", PR_Statements: snapshot.prs.futurequote || ""});
  // }
  // // PR FAQ
  // let prfaq = [];
  // if (snapshot.prfaq !== undefined) {
  //   snapshot.prfaq.CustomerFAQ.map((item) => {
  //     prfaq.push({Type: "Customer FAQ", Question: item.question, Answer: item.answer});
  //   })
  //   snapshot.prfaq.StakeholderFAQ.map((item) => {
  //     prfaq.push({Type: "Stakeholder FAQ", Question: item.question, Answer: item.answer});
  //   })
  // }
  //
  // // Strategy
  // let str = [];
  // if (snapshot.str !== undefined) {
  //   str = [
  //     {
  //       Overview: snapshot.str.overview || "",
  //       Approach: snapshot.str.approach || "",
  //       Conclusion: snapshot.str.conclusion || "",
  //     }
  //   ];
  // }
  //
  // // Recommendations
  // let recomm = [];
  // if (snapshot.recommendations !== undefined) {
  //   snapshot.recommendations.OrgAlign.map((item) => {
  //     recomm.push(
  //       {
  //         Piller: "Organizational Alignment",
  //         What: item.what,
  //         When: item.When,
  //         Rationale: item.rationale,
  //         Outcome: item.outcome,
  //         AWS_investment: item.awsinv,
  //         Next_Steps: item.nextsteps
  //       }
  //     );
  //   })
  //   recomm.push({Recommendations: " "});
  //   snapshot.recommendations.SolDev.map((item) => {
  //     recomm.push(
  //       {
  //         Piller: "Solution Development",
  //         What: item.what,
  //         When: item.When,
  //         Rationale: item.rationale,
  //         Outcome: item.outcome,
  //         AWS_investment: item.awsinv,
  //         Next_Steps: item.nextsteps
  //       }
  //     );
  //   })
  //   recomm.push({Recommendations: " "});
  //   snapshot.recommendations.ProcessOps.map((item) => {
  //     recomm.push(
  //       {
  //         Piller: "Process Operations",
  //         What: item.what,
  //         When: item.When,
  //         Rationale: item.rationale,
  //         Outcome: item.outcome,
  //         AWS_investment: item.awsinv,
  //         Next_Steps: item.nextsteps
  //       }
  //     );
  //   })
  //   recomm.push({Recommendations: " "});
  //   snapshot.recommendations.GTM.map((item) => {
  //     recomm.push(
  //       {
  //         Piller: "Go to Market",
  //         What: item.what,
  //         When: item.When,
  //         Rationale: item.rationale,
  //         Outcome: item.outcome,
  //         AWS_investment: item.awsinv,
  //         Next_Steps: item.nextsteps
  //       }
  //     );
  //   })
  //   recomm.push({Recommendations: " "});
  //   snapshot.recommendations.StaffProf.map((item) => {
  //     recomm.push(
  //       {
  //         Piller: "Staff Proficiency",
  //         What: item.what,
  //         When: item.When,
  //         Rationale: item.rationale,
  //         Outcome: item.outcome,
  //         AWS_investment: item.awsinv,
  //         Next_Steps: item.nextsteps
  //       }
  //     );
  //   })
  // }
  // -----

  const preqWS = XLSX.utils.json_to_sheet(preQues);
  preqWS['!cols'] = [{wch: 80}, {wch: 80}];

  // const swotWS = XLSX.utils.json_to_sheet(swot);
  // swotWS['!cols'] = [{wch: 20}, {wch: 80}];
  //
  // const uniWS = XLSX.utils.json_to_sheet(uni);
  // uniWS['!cols'] = [{wch: 20}, {wch: 80}];
  //
  // const oppsWS = XLSX.utils.json_to_sheet(opps);
  // oppsWS['!cols'] = [{wch: 30}, {wch: 80}, {wch: 80}, {wch: 80}];
  //
  // const prstmtWS = XLSX.utils.json_to_sheet(prstmt);
  // prstmtWS['!cols'] = [{wch: 20}, {wch: 80}];
  //
  // const prfaqWS = XLSX.utils.json_to_sheet(prfaq);
  // prfaqWS['!cols'] = [{wch: 20}, {wch: 80}, {wch: 80}]
  //
  // const strWS = XLSX.utils.json_to_sheet(str);
  // strWS['!cols'] = [{wch: 80}, {wch: 80}, {wch: 80}]
  //
  // const recommWS = XLSX.utils.json_to_sheet(recomm);
  // recommWS['!cols'] = [{wch: 30}, {wch: 50}, {wch: 20}, {wch: 50}, {wch: 50}, {wch: 50}, {wch: 50}, {wch: 50}]


  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, preqWS, "Pre-Questionnaire");
  // XLSX.utils.book_append_sheet(wb, swotWS, "SWOT");
  // XLSX.utils.book_append_sheet(wb, uniWS, "Unicorn-Super-powers");
  // XLSX.utils.book_append_sheet(wb, oppsWS, "GTM-Opportunities");
  // XLSX.utils.book_append_sheet(wb, prstmtWS, "PR-Statement");
  // XLSX.utils.book_append_sheet(wb, prfaqWS, "PR-FAQ");
  // XLSX.utils.book_append_sheet(wb, strWS, "Startegy");
  // XLSX.utils.book_append_sheet(wb, recommWS, "Recommendations");

  XLSX.writeFile(wb, sID + ".xlsx");

};

export default SSGenExcel;