import React, {useEffect, useState} from "react";
import {
  Button,
  ColumnLayout,
  Grid,
  Modal,
  SpaceBetween,
  Spinner,
  Textarea,
  Box,
  Header, Table
} from "../../../../aws-ui-components";
import "../../../../styles/servicehomepage.css"


const DTable = ({props, name, handleSave, updateEventChange, textRows, edit, fullList, columnDefinition, tableHeader}) => {
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (props !== undefined) {
      setFields(props);
    }
  }, []);

  function handleSelectionChange(selectedItems) {
      setFields([...selectedItems]);
      updateEventChange(true);
  }

  return (
    <div className="App" id="DivTable">

      <Table
            onSelectionChange={
          ({ detail }) => {
                handleSelectionChange(detail.selectedItems);
                handleSave(name, detail.selectedItems);
          }
            }
          selectedItems={fields}
          ariaLabels={{
            selectionGroupLabel: "Items selection",
            allItemsSelectionLabel: ({ selectedItems }) =>
                `${selectedItems.length} ${
                    selectedItems.length === 1 ? "item" : "items"
                } selected`,
            itemSelectionLabel: ({ selectedItems }, item) => {
              const isItemSelected = selectedItems.filter(
                  i => i.value === item.value
              ).length;
              return `${item.value} is ${
                  isItemSelected ? "" : "not"
              } selected`;
            }
          }}
          columnDefinitions={columnDefinition}
            isItemDisabled={(item) => (edit === true) ? item.value === '' : {}}
          items={fullList}
          loadingText="Loading resources"
          selectionType="multi"
          trackBy="value"
          // visibleColumns={[
          //   "sdp"
          // ]}
          wrapLines={true}

          empty={
            <Box textAlign="center" color="inherit">
              <b>No resources</b>
              <Box
                  padding={{ bottom: "s" }}
                  variant="p"
                  color="inherit"
              >
                No resources to display.
              </Box>
              {/*<Button>Create resource</Button>*/}
            </Box>
          }
          // filter={
          //   <TextFilter
          //       filteringPlaceholder="Find resources"
          //       filteringText=""
          //   />
          // }
          header={
            <Header>
              {tableHeader}
            </Header>
          }
          // pagination={
          //   <Pagination
          //       currentPageIndex={1}
          //       pagesCount={2}
          //       ariaLabels={{
          //         nextPageLabel: "Next page",
          //         previousPageLabel: "Previous page",
          //         pageLabel: pageNumber =>
          //             `Page ${pageNumber} of all pages`
          //       }}
          //   />
          // }
          // preferences={
          //   <CollectionPreferences
          //       title="Preferences"
          //       confirmLabel="Confirm"
          //       cancelLabel="Cancel"
          //       preferences={{
          //         pageSize: 10,
          //         visibleContent: [
          //           "variable",
          //           "value",
          //           "type",
          //           "description"
          //         ]
          //       }}
          //       pageSizePreference={{
          //         title: "Select page size",
          //         options: [
          //           { value: 10, label: "10 resources" },
          //           { value: 20, label: "20 resources" }
          //         ]
          //       }}
          //       visibleContentPreference={{
          //         title: "Select visible content",
          //         options: [
          //           {
          //             label: "Main distribution properties",
          //             options: [
          //               {
          //                 id: "variable",
          //                 label: "Variable name",
          //                 editable: false
          //               },
          //               { id: "value", label: "Text value" },
          //               { id: "type", label: "Type" },
          //               {
          //                 id: "description",
          //                 label: "Description"
          //               }
          //             ]
          //           }
          //         ]
          //       }}
          //   />
          // }
      />
    </div>
  );
}

export default DTable;
