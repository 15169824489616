/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

//d16x9jsru5lzlx.cloudfront.net
//redirectSignIn: "http://localhost:3000", redirectSignOut: "http://localhost:3000",

const host =
  window.location.hostname === "localhost"
    ? "http://localhost:3000"
    : "https://" + window.location.hostname;
const EnvAttributes = () => {
  switch (window.location.hostname) {
    case "test.pwmt.pmsa.aws.dev":
      return {
        CognitoDomain: "pwmttest.auth.us-west-2.amazoncognito.com",
        CognitoIPool: "us-west-2:5106342d-c276-497e-909b-39483f29ef15",
        CognitoUPool: "us-west-2_ZOKL3IiTX",
        CognitoWebClientID: "5fjdrqqeucouj3ouvrhipp00op",
      };
    case "localhost":
    case "dt2uttt3udrsp.cloudfront.net":
    case "dev.pwmt.pmsa.aws.dev":
      return {
        CognitoDomain: "pwmtdev.auth.us-west-2.amazoncognito.com",
        CognitoIPool: "us-west-2:73155313-b3ca-4959-b123-fd60ce586f9b",
        CognitoUPool: "us-west-2_wlseiVqj6",
        CognitoWebClientID: "23f91gb7vi5g7900rp7lah5daj",
      };
    case "pwmt.pmsa.aws.dev":
      return {
        CognitoDomain: "pwmt-prod.auth.us-west-2.amazoncognito.com",
        CognitoIPool: "us-west-2:4227a271-8035-4e88-963d-d1c64b397fab",
        CognitoUPool: "us-west-2_wb6ZVqnKJ",
        CognitoWebClientID: "4c598tf40avskd8l2he85eg604",
      };
  }
};

const awsmobile = {
  aws_project_region: "us-west-2",
  aws_cognito_identity_pool_id: EnvAttributes().CognitoIPool,
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: EnvAttributes().CognitoUPool,
  aws_user_pools_web_client_id: EnvAttributes().CognitoWebClientID,
  oauth: {
    domain: EnvAttributes().CognitoDomain,
    scope: ["profile", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn: host,
    redirectSignOut: host,
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
};

export default awsmobile;
