import React from 'react';
import {Box, Grid, Header, Link, Modal, SpaceBetween, Spinner, Table} from "../../aws-ui-components";
import {connect} from "react-redux";

import {deleteUploadFile, fetchFileURL, fetchSession, fetchUploadsList, uploadArtifact} from "../../actions";
import {Button} from "@amzn/awsui-components-react";

class SessionUploads extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isItemSelected: false,
      selectedItems: [],
      modalUploadVisible: false,
      uploadSubDisabled: false,
      fileUploading: false,
      selectedUploadFile: {},
      uploadStatus: "none",
      inputKey: "",
      loadingList: true,
      items: [],
      modalDeleteVisible: false
    }
    this.props.fetchUploadsList(this.props.selectedSessionInfo.sessionInfo.session.sessionID);
    this.state.items.sort(this.sortByProperty("LastModified"));
    this.props.turnOffFlashBar()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  sortByProperty(property) {
    return function (a, b) {
      if (a[property] > b[property])
        return 1;
      else if (a[property] < b[property])
        return -1;
      return 0;
    }
  }

  handleArtifactUpload = () => {
    this.setState({modalUploadVisible: true, selectedItems: []});
  }

  uploadFile = () => {
    this.setState({fileUploading: true})

    // upload
    this.props.uploadArtifact(this).then(
      () => {
        this.setState({uploadStatus: this.props.selectedSessionInfo.uploadsInfo.uploadStatus});
        this.setState({fileUploading: false});
        this.props.fetchUploadsList(this.props.selectedSessionInfo.sessionInfo.session.sessionID);
        this.setState({inputKey: Date.now()});
        this.setState({modalUploadVisible: false});
        document.getElementById("fileInput").value=null;
      }
    );
  }

  onUploadFileSelect = (event) => {
    event.preventDefault();
    this.setState({uploadSubDisabled: false});
    this.setState({selectedUploadFile: event.target.files[0]});
  }

  download = (url, filename) => {
      let a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", filename);
      a.setAttribute("target", "_blank");
      a.click();
  }

  getFile = () =>{
    if(this.state.selectedItems.length === 1){
      this.props.fetchFileURL(this.state.selectedItems[0].Key.split("/")[0],this.state.selectedItems[0].Key.split("/")[1]).then(
        () => {
          const url = (this.props.selectedSessionInfo.downloadFile.uploads !== undefined)?this.props.selectedSessionInfo.downloadFile.uploads.url:"";
          this.download(url, this.state.selectedItems[0].Key.split("/")[1]);
        }
      );
    }
  }

  handleArtifactDelete =() => {
    this.setState({modalDeleteVisible: true})
    this.props.deleteUploadFile(this.state.selectedItems[0].Key.split("/")[0],this.state.selectedItems[0].Key.split("/")[1]).then(
      () => {
        this.props.fetchUploadsList(this.props.selectedSessionInfo.sessionInfo.session.sessionID).then(
          ()=>{ this.setState({modalDeleteVisible: false});}
        );
      }
    );
  }

  render() {
    let loading = true;

    if (this.props.selectedSessionInfo !== undefined)
      if (this.props.selectedSessionInfo.uploadsInfo !== undefined)
        if (this.props.selectedSessionInfo.uploadsInfo.uploads !== undefined)
          if (this.props.selectedSessionInfo.uploadsInfo.uploads.Contents !== undefined) {
            this.state.items = this.props.selectedSessionInfo.uploadsInfo.uploads.Contents
            loading = false;
          }
    return (
      <div>
        <Modal
          visible={this.state.modalUploadVisible}
          onDismiss={() => this.setState({modalUploadVisible: false})}
          closeAriaLabel="Close modal"
          size="medium"
          header="Upload Artifact"
          footer={
            <Grid
              gridDefinition={[
                {colspan: {default: 6}, offset: {default: 0}},
                {colspan: {default: 6}, offset: {default: 0}},
              ]}
            >
              <Box> {(this.state.fileUploading) ? <div>Uploading<Spinner/></div> : ""} </Box>
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="primary"
                          disabled={this.state.uploadSubDisabled}
                          onClick={async detail => {
                            await this.uploadFile(detail)
                          }}
                  >Upload</Button>
                </SpaceBetween>
              </Box>
            </Grid>
          }
        >
          <input type="file"
                 accept=".doc,.docx,.xlsx,.xls,.ppt,.pptx,.pdf,.csv,immage/*,video/*"
                 id="fileInput"
                 onChange={this.onUploadFileSelect} />
        </Modal>

        <Modal
          visible={this.state.modalDeleteVisible}
          size="medium"
          header="Deleting Artifact"
        >
          <Spinner/>
        </Modal>

        <Box padding={{top: "xxl"}}>
          <Grid
            gridDefinition={[
              {colspan: {default: 10}, offset: {default: 1}}
            ]}
          >
            <div>
              <Header
                variant="h2"
                actions={
                  <SpaceBetween direction="horizontal" size="s">
                    <Button disabled={this.state.selectedItems.length === 0}
                              onClick={detail => {
                              this.handleArtifactDelete(detail)
                            }}
                    > Delete </Button>
                    <Button disabled={this.state.selectedItems.length !== 1}
                            onClick={() => {
                              this.getFile()
                            }}
                    > Download </Button>
                    <Button variant="primary"
                            onClick={detail => {
                              this.handleArtifactUpload(detail)
                            }}
                    > Upload </Button>
                  </SpaceBetween>
                }
              >
                Uploads
              </Header>
              <p/>

              <Table
                loading={loading}
                selectionType="single"
                header={
                  <Header
                    counter={
                      this.state.selectedItems.length
                        ? "Selected: (" + this.state.selectedItems.length + "/" + this.state.items.length + ")"
                        : "Selected: (0/" + this.state.items.length + ")"
                    }
                  >
                  </Header>
                }
                selectedItems={this.state.selectedItems}
                onSelectionChange={({detail}) => {
                  this.setState({selectedItems: detail.selectedItems})
                }
                }
                loadingText="Loading resources"
                columnDefinitions={[
                  {
                    id: "Key",
                    header: "Name",
                    cell: item => (
                     item.Key.split("/")[1] || "-"
                    ),
                    width: 300
                  },
                  {
                    id: "LastModified",
                    header: "Date",
                    cell: item => date(item.LastModified) || "-",
                    width: 300
                  }
                ]}

                items={this.state.items}
                wrapLines
                empty={
                  <Box textAlign="center" color="inherit">
                    <b>No items to display.</b>
                  </Box>
                }
              />
            </div>
          </Grid>
        </Box>
      </div>
    )
  }
}

const date = (dateValue) => {
  const d = new Date(dateValue);
  return d.toDateString();
}

const mapStateToProps = (state) => {
  return {selectedSessionInfo: state};
}

export default connect(mapStateToProps, {
  fetchSession, uploadArtifact, fetchUploadsList, fetchFileURL, deleteUploadFile
})(SessionUploads);
