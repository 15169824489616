import React from 'react';
import {Box, ColumnLayout, Container, Header, Tabs, Textarea,} from "../../../aws-ui-components";
import {connect} from "react-redux";
import {fetchSession, updateACTMI, updateSession} from "../../../actions";
import DField from "../resources/form/DField";
import DTable from "../resources/form/DTable";
import SessionHold from "../resources/form/SessionHold";
import {milestonesLabel, sdps, competencies, programs, benefits, labels} from "./resources/constants";

class OPRActivitiesMilestones extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            actmi: {
                milestone1SDPs: [],
                milestone2SDPs: [],
                milestone3SDPs: [],
                milestone1Competencies: [],
                milestone2Competencies: [],
                milestone3Competencies: [],
                milestone1Programs: [],
                milestone2Programs: [],
                milestone3Programs: [],
                recommendationBenefits: [],
                actmiNotes: ""
            },
            edit: false
        }

        this.sdpTableColumnDefinition = [
            {
                id: "sdp",
                header: "SDPs",
                cell: e => e.value,
                sortingField: "value"
            },
        ];
        this.compTableColumnDefinition = [
            {
                id: "competencies",
                header: "Competencies",
                cell: e => e.value,
                sortingField: "value"
            },
        ];
        this.programTableColumnDefinition = [
            {
                id: "programs",
                header: "Programs",
                cell: e => e.value,
                sortingField: "value"
            },
        ];
        this.benefitsTableColumnDefinition = [
            {
                id: "benefits",
                header: "Benefits Description",
                cell: e => e.value,
                sortingField: "value"
            },
        ];
        if (this.props.store.sessionInfo.session.answers !== undefined && this.props.store.sessionInfo.session.answers !== null) {
            const vals = this.props.store.sessionInfo.session.answers;
            let actmi = [];
            if (vals !== undefined && vals !== null) {
                if (vals.actmi !== undefined && vals.actmi !== null) {
                    actmi = vals.actmi;
                    if (actmi.milestone1SDPs !== undefined && actmi.milestone1SDPs !== null)
                        this.state.actmi.milestone1SDPs = actmi.milestone1SDPs
                    if (actmi.milestone2SDPs !== undefined && actmi.milestone2SDPs !== null)
                        this.state.actmi.milestone2SDPs = actmi.milestone2SDPs
                    if (actmi.milestone3SDPs !== undefined && actmi.milestone3SDPs !== null)
                        this.state.actmi.milestone3SDPs = actmi.milestone3SDPs
                    if (actmi.milestone1Competencies !== undefined && actmi.milestone1Competencies !== null)
                        this.state.actmi.milestone1Competencies = actmi.milestone1Competencies
                    if (actmi.milestone2Competencies !== undefined && actmi.milestone2Competencies !== null)
                        this.state.actmi.milestone2Competencies = actmi.milestone2Competencies
                    if (actmi.milestone3Competencies !== undefined && actmi.milestone3Competencies !== null)
                        this.state.actmi.milestone3Competencies = actmi.milestone3Competencies
                    if (actmi.milestone1Programs !== undefined && actmi.milestone1Programs !== null)
                        this.state.actmi.milestone1Programs = actmi.milestone1Programs
                    if (actmi.milestone2Programs !== undefined && actmi.milestone2Programs !== null)
                        this.state.actmi.milestone2Programs = actmi.milestone2Programs
                    if (actmi.milestone3Programs !== undefined && actmi.milestone3Programs !== null)
                        this.state.actmi.milestone3Programs = actmi.milestone3Programs
                    if (actmi.recommendationBenefits !== undefined && actmi.recommendationBenefits !== null)
                        this.state.actmi.recommendationBenefits = actmi.recommendationBenefits
                    if (actmi.actmiNotes !== undefined && actmi.actmiNotes !== null)
                        this.state.actmi.actmiNotes = actmi.actmiNotes
                } else {
                    this.state.actmi.recommendationBenefits = [...benefits]
                }
            }
        }
    }

    handleSave = (name, values) => {
        switch (name) {
            case "milestone1SDPs":
                this.state.actmi.milestone1SDPs = [...values];
                break;
            case "milestone2SDPs":
                this.state.actmi.milestone2SDPs = [...values];
                break;
            case "milestone3SDPs":
                this.state.actmi.milestone3SDPs = [...values];
                break;
            case "milestone1Competencies":
                this.state.actmi.milestone1Competencies = [...values];
                break;
            case "milestone2Competencies":
                this.state.actmi.milestone2Competencies = [...values];
                break;
            case "milestone3Competencies":
                this.state.actmi.milestone3Competencies = [...values];
                break;
            case "milestone1Programs":
                this.state.actmi.milestone1Programs = [...values];
                break;
            case "milestone2Programs":
                this.state.actmi.milestone2Programs = [...values];
                break;
            case "milestone3Programs":
                this.state.actmi.milestone3Programs = [...values];
                break;
            case "recommendationBenefits":
                this.state.actmi.recommendationBenefits = [...values];
                break;
        }
        this.props.updateACTMI(this).then(async () => {
            this.props.saveToDB();
        });
    }

    handleTextChange = (name, value) => {
        this.setState({actmi: {...this.state.actmi, [name]: value}});
        this.props.updateEventChange(true);
        this.props.updateACTMI(this);
    }

    handleTextSave = () => {
        this.props.updateACTMI(this).then(async () => {
            this.props.saveToDB();
        });
    }

    componentWillUnmount() {
        this.props.saveToDB();
    }

    sessionHoldHandler = (e) => {
        this.setState({edit: e})
    }

    render() {


        return (
            <div>
                <SessionHold sessionHoldHandler={this.sessionHoldHandler}
                             sessionID={this.props.store.sessionInfo.session.sessionID}/>
                <Tabs
                    tabs={[
                        {
                            label: "SDPs",
                            id: "sdps",
                            content: <div>
                                <Box margin='l'>
                                    <div>
                                        <ColumnLayout columns={3}>
                                            <DTable props={this.state.actmi.milestone1SDPs} name="milestone1SDPs"
                                                    handleSave={this.handleSave}
                                                    updateEventChange={this.props.updateEventChange}
                                                    columnDefinition={this.sdpTableColumnDefinition}
                                                    fullList={sdps} tableHeader={milestonesLabel.milestone1} edit={this.state.edit}/>
                                            <DTable props={this.state.actmi.milestone2SDPs} name="milestone2SDPs"
                                                    handleSave={this.handleSave}
                                                    updateEventChange={this.props.updateEventChange}
                                                    columnDefinition={this.sdpTableColumnDefinition}
                                                    fullList={sdps} tableHeader={milestonesLabel.milestone2} edit={this.state.edit}/>
                                            <DTable props={this.state.actmi.milestone3SDPs}
                                                    name="milestone3SDPs" handleSave={this.handleSave}
                                                    updateEventChange={this.props.updateEventChange}
                                                    columnDefinition={this.sdpTableColumnDefinition}
                                                    fullList={sdps} tableHeader={milestonesLabel.milestone3} edit={this.state.edit}/>
                                        </ColumnLayout>
                                    </div>

                                </Box>
                            </div>
                        },
                        {
                            label: "Competencies",
                            id: "competencies",
                            content: <div>
                                <Box margin="l">
                                    <div>
                                        <ColumnLayout columns={3}>
                                            <DTable props={this.state.actmi.milestone1Competencies} name="milestone1Competencies"
                                                    handleSave={this.handleSave}
                                                    updateEventChange={this.props.updateEventChange}
                                                    columnDefinition={this.compTableColumnDefinition}
                                                    fullList={competencies} tableHeader={milestonesLabel.milestone1} edit={this.state.edit}/>
                                            <DTable props={this.state.actmi.milestone2Competencies} name="milestone2Competencies"
                                                    handleSave={this.handleSave}
                                                    updateEventChange={this.props.updateEventChange}
                                                    columnDefinition={this.compTableColumnDefinition}
                                                    fullList={competencies} tableHeader={milestonesLabel.milestone2} edit={this.state.edit}/>
                                            <DTable props={this.state.actmi.milestone3Competencies} name="milestone3Competencies"
                                                    handleSave={this.handleSave}
                                                    updateEventChange={this.props.updateEventChange}
                                                    columnDefinition={this.compTableColumnDefinition}
                                                    fullList={competencies} tableHeader={milestonesLabel.milestone3} edit={this.state.edit}/>
                                        </ColumnLayout>
                                    </div>
                                </Box>
                            </div>
                        },
                        {
                            label: "Programs",
                            id: "programs",
                            content: <div>
                                <Box margin="l">
                                    <div>
                                        <ColumnLayout columns={3}>
                                            <DTable props={this.state.actmi.milestone1Programs} name="milestone1Programs"
                                                    handleSave={this.handleSave}
                                                    updateEventChange={this.props.updateEventChange}
                                                    columnDefinition={this.programTableColumnDefinition}
                                                    fullList={programs} tableHeader={milestonesLabel.milestone1} edit={this.state.edit}/>
                                            <DTable props={this.state.actmi.milestone2Programs} name="milestone2Programs"
                                                    handleSave={this.handleSave}
                                                    updateEventChange={this.props.updateEventChange}
                                                    columnDefinition={this.programTableColumnDefinition}
                                                    fullList={programs} tableHeader={milestonesLabel.milestone2} edit={this.state.edit}/>
                                            <DTable props={this.state.actmi.milestone3Programs} name="milestone3Programs"
                                                    handleSave={this.handleSave}
                                                    updateEventChange={this.props.updateEventChange}
                                                    columnDefinition={this.programTableColumnDefinition}
                                                    fullList={programs} tableHeader={milestonesLabel.milestone3} edit={this.state.edit}/>
                                        </ColumnLayout>
                                    </div>
                                </Box>
                            </div>
                        },
                        {
                            label: "Benefits",
                            id: "benefits",
                            content: <div>
                                <Box margin="l">
                                    <div>
                                        <ColumnLayout columns={1}>
                                            <DTable props={this.state.actmi.recommendationBenefits} name="recommendationBenefits"
                                                    handleSave={this.handleSave}
                                                    updateEventChange={this.props.updateEventChange}
                                                    columnDefinition={this.benefitsTableColumnDefinition}
                                                    fullList={benefits} tableHeader={labels.benefits} edit={this.state.edit}/>
                                        </ColumnLayout>
                                    </div>
                                </Box>
                            </div>
                        },
                        {
                            label: "Notes",
                            id: "Notes",
                            content: <Container>
                                <Textarea
                                    disabled={!this.state.edit}
                                    autoComplete={false}
                                    name="actmiNotes"
                                    onChange={({detail}) => this.handleTextChange("actmiNotes", detail.value)}
                                    onBlur={(detail) => {
                                        detail.preventDefault();
                                        this.handleTextSave()
                                    }}
                                    value={this.state.actmi.actmiNotes}
                                    rows={(this.state.actmi.actmiNotes !== null) ? (this.state.actmi.actmiNotes.length / 160 + this.state.actmi.actmiNotes.split("\n").length) : 1}
                                    placeholder="Enter your notes here..."
                                />
                            </Container>
                        },
                    ]}
                />

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {store: state};
}

export default connect(mapStateToProps, {
    updateACTMI, fetchSession, updateSession
})(OPRActivitiesMilestones);
