export const SESSION_TYPE_OPTIONS = [
  { label: "Working Backwards Workshop", value: "wbw"},
  { label: "Onboarding Partner Roadmap", value: "opr"},
];

/*
{ label: "Partner Offer Planning", value: "pop" },
  { label: "CCoE Planning", value: "ccoe" },
  { label: "Working Backwards Partner Planning", value: "wbpp"},
*/
