import React from "react";

import PartnerProfile from '../PartnerProfile';
import SessionUploads from '../SessionUploads';
import OPRPreQ from './OPRPreQ';
import OPRStrategy from './OPRStrategy';
import OPRNarrative from './OPRNarrative';
import OPRAddRecomm from "./OPRAddRecomm";
import OPRActivitiesMilestones from "./OPRActivitiesMilestones";

export const items = [
  { type: "link", text: "Partner Profile", href: PartnerProfile },
  { type: "link", text: "Pre-Questionnaire", href: OPRPreQ },
  { type: "divider"},
  {
    type: "link-group", text: <h4>Envisioning</h4>,
    items: [
      {type: "link", text: "Priorities & Initiatives", href: OPRStrategy},
    ]
  },
  { type: "divider"},
  {
    type: "link-group", text: <h4>Recommendations</h4>,
    items: [
      {type: "link", text: "Activities & Milestones", href: OPRActivitiesMilestones},
      {type: "link", text: "Additional Recommendations", href: OPRAddRecomm},
    ]
  },
  { type: "divider"},
  {
    type: "link-group", text: <h4>Rendered Outputs</h4>,
    items: [
      {type: "link", text: "Complete Narrative", href: OPRNarrative},
    ]
  },
  { type: "divider"},
  { type: "link", text: "Additional Artifacts (uploads)", href: SessionUploads },
];