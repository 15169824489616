import '@amzn/awsui-global-styles/polaris.css';
import "../../../styles/servicehomepage.css";
import {
  HelpPanel, Icon
} from "../../../aws-ui-components";

const OPRSessionTools = () => {
  return (
      <HelpPanel
          header={<h2>Onboarding Partner Roadmap</h2>}
          footer={
            <div>
              <h3>
                WBW Resources <Icon name="external"/>
              </h3>
              <ul>
                <li>
                  <a href="/WBW-PreQ.xlsx" rel="noreferrer" target="_blank">
                    WBW Pre Questionnaire (For easy uploads into this tool)
                  </a>
                </li>
                <li>
                  <a href="https://w.amazon.com/bin/view/AWS/Teams/PartnerSA/Partner_Management/Workstreams/FP-01/" rel="noreferrer" target="_blank">
                    Working Backwards Workshop Full (Wiki)
                  </a>
                </li>
                <li>
                  <a href="https://w.amazon.com/bin/view/AWS/Teams/PartnerSA/Partner_Management/Workstreams/FP-01-1" rel="noreferrer" target="_blank">
                    Working Backwards Workshop Lite (Wiki)
                  </a>
                </li>
                <li>
                  <a href="https://amazon.awsapps.com/workdocs/index.html#/document/a69f57ffb8fbb41fa044eee2d11bda53f798236540c617020fb710fa694dede9" rel="noreferrer" target="_blank">
                    WBW Lite - Prescriptive Guidance (Wiki)
                  </a>
                </li>
                <h5>General Information <Icon name="external"/> </h5>
                <li>
                  <a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwj6ssShpI3wAhXIuZ4KHU1rDGoQwqsBMAB6BAgOEAk&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D4VOgUMqcHhU&usg=AOvVaw3vJKV7om43zZvfulqzy3mm" rel="noreferrer" target="_blank">
                    Jeff Bezos Video (Youtube)
                  </a>
                </li>
                <li>
                  <a href="https://w.amazon.com/bin/view/AWS_Partner_Network/APN_Differentiation_Programs/" rel="noreferrer" target="_blank">
                    Differentiation Programs (Wiki)
                  </a>
                </li>
                <li>
                  <a href="https://w.amazon.com/bin/view/APN_Customer_Engagements_Program/" rel="noreferrer" target="_blank">
                    ACE (Wiki)
                  </a>
                </li>
                <h5>Break Timer <Icon name="external"/> </h5>
                <li>
                  <a href="https://dev.timer.pmsa.aws.dev?timer=5" rel="noreferrer" target="_blank">
                    5 minute Break Timer
                  </a>
                </li>
                <li>
                  <a href="https://dev.timer.pmsa.aws.dev?timer=10" rel="noreferrer" target="_blank">
                    10 minute Break Timer
                  </a>
                </li>
                <li>
                  <a href="https://dev.timer.pmsa.aws.dev?timer=15" rel="noreferrer" target="_blank">
                    15 minute Break Timer
                  </a>
                </li>
              </ul>
            </div>
          }
      >
        <p>
          Working Backwards Workshop is used to provide recommendations/roadmap for Partner
          that could accelerate towards partner's vision and goals.
        </p>
      </HelpPanel>
  );
}

export default OPRSessionTools;

