import React from 'react';
import {Tabs, Box, Container, Grid, Header, Textarea} from "../../../aws-ui-components";
import {connect} from "react-redux";

import {fetchSession, updateStr, updateSession} from "../../../actions";
import SessionHold from "../resources/form/SessionHold";
import DField from "../resources/form/DField";

class OPRStrategy extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      str: {
        prioini: [],
      },
      edit:false
    }

    if (this.props.store.sessionInfo.session.answers !== undefined && this.props.store.sessionInfo.session.answers !== null) {
      const vals = this.props.store.sessionInfo.session.answers;
      let str = [];
      if (vals !== undefined && vals !== null) {
        if (vals.str !== undefined &&  vals.str !== null) {
          str = vals.str;
          if (str.prioini !== undefined && str.prioini !== null)
            this.state.str.prioini = str.prioini
        }
      }
    }
  }

  handleTextChange = (name, value) => {
    this.setState({str: {...this.state.str, [name]: value }});
    this.props.updateEventChange(true);
    this.props.updateStr(this);
  }
  handleSave = (name, values) => {
    switch (name) {
      case "prioini":
        this.state.str.prioini = [...values];
        break;
    }
    this.props.updateStr(this).then(async () => {
      this.props.saveToDB();
    });
  }

  handleTextSave = () => {
    this.props.updateStr(this).then(async () => {
      this.props.saveToDB();
    });
  }

  componentWillUnmount() {
    this.props.saveToDB();
  }

  sessionHoldHandler = (e) => {
    this.setState({edit: e})
  }

  render() {
    return(
      <div>
        <SessionHold sessionHoldHandler={this.sessionHoldHandler} sessionID = {this.props.store.sessionInfo.session.sessionID}/>
        <Tabs
          tabs={[
            {
              label: "Priorities & Initiatives",
              id: "PrioIni",
              content:   <div>
                <Box margin="l">
                  <Container
                      header={
                        <Header variant="h2" description="">
                          Priorities & Initiatives
                        </Header>
                      }
                  >
                    <DField props={this.state.str.prioini} name="prioini" handleSave={this.handleSave}
                            updateEventChange={this.props.updateEventChange} edit={this.state.edit}/>
                  </Container>
                </Box>
              </div>
            },
            ]}
          />
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {store: state};
}

export default connect(mapStateToProps, {
  fetchSession, updateStr, updateSession
})(OPRStrategy);