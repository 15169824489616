import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Form,
  Grid,
  Alert,
  Input,
  Select,
  SpaceBetween, Spinner, Modal
} from "../../aws-ui-components";

import Header from "./MainHeader";
import { SESSION_TYPE_OPTIONS } from "./resources/CreateSession-config";
import React from "react";


//removed <Header/>
export default props => (

  <div>

    <Modal
      visible={props.this.state.loading}
      size="small"
      header ={<div > Creating Session... </div>}
    >
      <div> <Spinner size="normal" /> </div>
    </Modal>

    <Alert
      visible={(props.this.state.status >= 400)?true:false}
      dismissAriaLabel="Close alert"
      header="Error"
    >
      Please make sure you have entered the proper SPMID for partner.
      SMPID is a numeric only ID. Eg. 7656.
    </Alert>

    <Grid
      gridDefinition={[
        {
          colspan: { default: 8 }, offset: { default: 1 }
        }
      ]}
    >
    <Box padding={{ top: "l" }}>
        <div>
          <Form
            actions={
                <Button
                  variant="primary"
                  onClick={props.this.handleSubmit}
                  disabled={
                    !(
                      props.this.state.sessionName.length > 0 &&
                      props.this.state.partnerID.length > 0 &&
                      props.this.state.sessionType !== undefined &&
                      props.this.state.sessionType !== null &&
                      props.this.state.sessionType.value !== "" &&
                      props.this.state.sessionMgmt.SFDCEntry.length > 0
                    )
                  }
                >
                  Create
                </Button>
            }
            header={<h2>Enter New Session Details {props.this.state.submit}</h2>}
          >
            <SpaceBetween size="s">
              <Container>
                <ColumnLayout columns={1} variant="text-grid">
                    <Input
                      type="text"
                      autoFocus
                      placeholder="Enter Session Name (cannot have any special chars, except - and _)"
                      onChange={event =>
                        props.this.handleChange("sessionName", "input", event)
                      }
                      value={props.this.state.sessionName}
                    />

                    <Select
                      options={SESSION_TYPE_OPTIONS}
                      selectedAriaLabel="Selected"
                      onChange={event =>
                        props.this.handleChange("sessionType", "select", event)
                      }
                      placeholder="Choose Session Type"
                      selectedOption={props.this.state.sessionType}
                    />

                    <Input
                      type="text"
                      placeholder="Enter Partner SPMS ID (Only accepts numbers)"
                      onChange={event =>
                        props.this.handleChange("partnerID", "input", event)
                      }
                      value={props.this.state.partnerID}
                    />

                    <Input
                      type="text"
                      placeholder="Enter SFDC Opportunity / Task ID"
                      onChange={event =>
                        props.this.handleChange("SFDCEntry", "input", event)
                      }
                      value={props.this.state.sessionMgmt.SFDCEntry}
                    />

                </ColumnLayout>
              </Container>
            </SpaceBetween>
          </Form>
          <p/>
           <h4>Note:</h4>
            <Box>1. Partner name can only have alpha-numerics, hyphen(-) and underscore(_)
              <Box> [^a-zA-Z0-9\-\_] </Box>
            </Box>
            <Box>2. Partner ID is the SPMS ID, which is a numeric value
              <Box> [^0-9] </Box>
            </Box>
            <Box>3. Partner naming best practice - Name it meaningful
            </Box>
            <Box>
              <Box>  Example:  Oktank-WBW-2021 or Oktank-2021 etc.</Box>
            </Box>
        </div>
      </Box>
    </Grid>
  </div>
);