import React from 'react';
import {
  Alert,
  Box,
  Button,
  ColumnLayout,
  Container,
  DatePicker,
  Grid,
  Header,
  Icon,
  ProgressBar,
  SpaceBetween,
  Table,
  Tabs,
  Input
} from "../../aws-ui-components";
import {connect} from "react-redux";
import {fetchSession, updateSession, updateSessionMgmt} from "../../actions";
import DField from "./resources/form/DField";
import DAttendees from "./resources/form/DAttendees";
import SessionHold from "./resources/form/SessionHold";
import SSGenExcel from "./SSGenExcelIndex";

const WBW_Event_TYPE_OPTIONS = [
  {label: "Envision Partner", value: "wbw-envision"},
  {label: "Roadmap Readout Partner", value: "wbw-rr"}
];


class SessionMgmt extends React.Component {
  WBW_COMPANIES_OPTIONS;

  constructor(props) {
    super(props);

    this.state = {
      sessionMgmt: {
        PreQStatus: {completionDate: ""}, // 20%
        EnvisioningStatus: {completionDate: ""}, //50%
        RecommendationsStatus: {completionDate: ""}, //80%
        RoadmapAlignmentStatus: {completionDate: ""}, //100%
        accessRW: [],
        accessR: [],
        attendees: [],
        snapshots: [],
        SFDCEntry: ""
      },
      edit: false
    }

    if (this.props.store.sessionInfo.session.sessionMgmt !== undefined && this.props.store.sessionInfo.session.sessionMgmt !== null) {
      const sm = this.props.store.sessionInfo.session.sessionMgmt;
      if (sm.PreQStatus !== undefined && sm.PreQStatus !== null)
        this.state.sessionMgmt.PreQStatus = sm.PreQStatus;
      if (sm.EnvisioningStatus !== undefined && sm.EnvisioningStatus !== null)
        this.state.sessionMgmt.EnvisioningStatus = sm.EnvisioningStatus;
      if (sm.RecommendationsStatus !== undefined && sm.RecommendationsStatus !== null)
        this.state.sessionMgmt.RecommendationsStatus = sm.RecommendationsStatus;
      if (sm.PreQStatus !== undefined && sm.PreQStatus !== null)
        this.state.sessionMgmt.RoadmapAlignmentStatus = sm.RoadmapAlignmentStatus;
      if (sm.accessRW !== undefined && sm.accessRW !== null)
        this.state.sessionMgmt.accessRW = sm.accessRW
      if (sm.accessR !== undefined && sm.accessR !== null)
        this.state.sessionMgmt.accessR = sm.accessR;
      if (sm.attendees !== undefined && sm.attendees !== null)
        this.state.sessionMgmt.attendees = sm.attendees;
      if (sm.SFDCEntry !== undefined && sm.SFDCEntry !== null)
        this.state.sessionMgmt.SFDCEntry = sm.SFDCEntry;
      if (sm.snapshots !== undefined && sm.snapshots !== null)
        this.state.sessionMgmt.snapshots = sm.snapshots;
    }

    let partnerName = "";

    if (this.props.store.sessionInfo.session.partnerInfo !== undefined) {
      partnerName = this.props.store.sessionInfo.session.partnerInfo.name;
    }
    this.WBW_COMPANIES_OPTIONS = [
      {label: "AWS", value: "aws"},
      {label: partnerName, value: partnerName}
    ];

  }

  handleChange = (name, value) => {
    switch (name) {
      case "PreQStatus":
        this.setState({sessionMgmt: {...this.state.sessionMgmt, PreQStatus: {completionDate: value}}});
        break;
      case "EnvisioningStatus":
        this.setState({sessionMgmt: {...this.state.sessionMgmt, EnvisioningStatus: {completionDate: value}}});
        break;
      case "RecommendationsStatus":
        this.setState({sessionMgmt: {...this.state.sessionMgmt, RecommendationsStatus: {completionDate: value}}});
        break;
      case "RoadmapAlignmentStatus":
        this.setState({sessionMgmt: {...this.state.sessionMgmt, RoadmapAlignmentStatus: {completionDate: value}}});
        break;
      case "SFDCEntry":
        this.setState({sessionMgmt: {...this.state.sessionMgmt, SFDCEntry: value}});
        break;
    }
    this.props.updateEventChange(true);
  }

  handleSave = (name, values) => {
    switch (name) {
      case "accessRW":
        this.state.sessionMgmt.accessRW = [...values];
        break;
      case "accessR":
        this.state.sessionMgmt.accessR = [...values];
        break;
      case "Attendee":
        this.state.sessionMgmt.attendees = [...values];
        break;
      case "Snapshots":
        this.state.sessionMgmt.snapshots = [...values];
        break;
      case "SFDCEntry":
        this.state.sessionMgmt.SFDCEntry = values;
        break;
    }
    this.props.updateSessionMgmt(this).then(async () => {
      await this.props.saveToDB();
    });
  }

  handleAddSnapshot = (e) => {
    e.preventDefault();
    const values = this.state.sessionMgmt.snapshots;
    const d = new Date;
    values.push(
      {
        id: this.props.store.sessionInfo.session.sessionID + "-" + d.getTime(), snapshotDate: d.getTime(),
        snapshot: this.props.store.sessionInfo.session.answers
      });
    this.setState({sessionMgmt: {...this.state.sessionMgmt, snapshots: [...values]}});
    this.props.updateSessionMgmt(this).then(() => {
      this.props.updateEventChange(true);
      this.props.saveToDB();
    });
  }

  handleDownloadSnapshot = (snapshotID) => {

    const snapshots = this.state.sessionMgmt.snapshots;
    let snapshot = null;
    snapshots.map((s) => {
      if (s.id === snapshotID) {
        snapshot = {...s.snapshot};
      }
    })

    if (snapshot !== null) {
      switch (this.props.store.sessionInfo.session.sessionType.value){
        case ('wbw'): SSGenExcel['wbw'](snapshot, snapshotID); break;
        case ('opr'): SSGenExcel['opr'](snapshot, snapshotID); break;
        default:
          const blob = new Blob([JSON.stringify(snapshot)]);
          const fileDownloadUrl = URL.createObjectURL(blob);
          this.setState ({fileDownloadUrl: fileDownloadUrl},
            () => {
              this.dofileDownload.click();
              URL.revokeObjectURL(fileDownloadUrl);  // free up storage--no longer needed.
              this.setState({fileDownloadUrl: ""})
            })
          break;
      }
    } else {
      console.log("Snapshot not found.")
    }
  }

  componentWillUnmount() {
    this.props.saveToDB();
  }

  sessionHoldHandler = (e) => {
    this.setState({edit: e})
  }

  render() {
    let sessionName = "";
    let sessionType = "";
    let partnerName = "";
    let createdBy = "";
    let createdOn = "";
    let partnerPDX = "";
    let sessionOwner = false;


    if (this.props.store.sessionInfo !== undefined)
      if (this.props.store.sessionInfo.session !== undefined) {
        sessionName = this.props.store.sessionInfo.session.sessionName;
        createdBy = this.props.store.sessionInfo.session.createdBy;
        createdOn = this.props.store.sessionInfo.session.createdOn;
        sessionType = this.props.store.sessionInfo.session.sessionType.label;
        sessionOwner = (this.props.store.sessionInfo.session.createdByCognitoId.toLowerCase() === this.props.store.auth.user.username.toLowerCase())

        if (this.props.store.sessionInfo.session.partnerInfo !== undefined) {
          partnerName = this.props.store.sessionInfo.session.partnerInfo.name;
        }
        if (this.props.store.sessionInfo.session.partnerInfo !== undefined) {
          partnerPDX = this.props.store.sessionInfo.session.partnerInfo.pdm || "";
        }
      }

    const today = new Date;
    let progress = 0;

    if (new Date(this.state.sessionMgmt.PreQStatus.completionDate) < today) {progress = 20;}
    if (new Date(this.state.sessionMgmt.EnvisioningStatus.completionDate) < today) {progress = 50;}
    if (new Date(this.state.sessionMgmt.RecommendationsStatus.completionDate) < today) {progress = 80;}
    if (new Date(this.state.sessionMgmt.RoadmapAlignmentStatus.completionDate) < today) {progress = 100;}

    const tabsContent = [
      {
        label: "Session Status",
        id: "Session Status",
        content:
          <Box>
            <Container>
              <ProgressBar
                status="in-progress"
                value={progress}
                label="Progress"
              />
            </Container>
            <p></p>
            <Container
              header={
                <Header
                  variant="h3"
                  description=""
                >
                  <Box>
                    <ColumnLayout columns={2}>
                      <div>Session Status</div>
                    </ColumnLayout>
                  </Box>
                </Header>
              }
            >
              <Grid gridDefinition={[{colspan: 6}, {colspan: 4}]}>
                <Box variant="h5">
                  Pre Questionnaire Received:
                </Box>
                <DatePicker
                  readOnly={!this.state.edit}
                  onChange={({detail}) => this.handleChange("PreQStatus", detail.value)}
                  onBlur={({detail}) => this.handleSave()}
                  value={this.state.sessionMgmt.PreQStatus.completionDate}
                  openCalendarAriaLabel={selectedDate =>
                    "Choose Date" +
                    (selectedDate
                      ? `, selected date is ${selectedDate}`
                      : "")
                  }
                  nextMonthAriaLabel="Next month"
                  placeholder="YYYY/MM/DD"
                  previousMonthAriaLabel="Previous month"
                  todayAriaLabel="Today"
                  onBlur={({detail}) => this.handleSave()}
                />
              </Grid>

              <Grid
                gridDefinition={[{colspan: 6}, {colspan: 4}]}
              >
                <Box variant="h5">
                  Workshop Completion:
                </Box>
                <DatePicker
                  readOnly={!this.state.edit}
                  onChange={({detail}) => this.handleChange("EnvisioningStatus", detail.value)}
                  value={this.state.sessionMgmt.EnvisioningStatus.completionDate}
                  openCalendarAriaLabel={selectedDate =>
                    "Choose Date" +
                    (selectedDate
                      ? `, selected date is ${selectedDate}`
                      : "")
                  }
                  nextMonthAriaLabel="Next month"
                  placeholder="YYYY/MM/DD"
                  previousMonthAriaLabel="Previous month"
                  todayAriaLabel="Today"
                  onBlur={({detail}) => this.handleSave()}
                />

              </Grid>

              <Grid
                gridDefinition={[{colspan: 6}, {colspan: 4}]}
              >
                <Box variant="h5">
                  Recommendations Provided :
                </Box>
                <DatePicker
                  readOnly={!this.state.edit}
                  onChange={({detail}) => this.handleChange("RecommendationsStatus", detail.value)}
                  value={this.state.sessionMgmt.RecommendationsStatus.completionDate}
                  openCalendarAriaLabel={selectedDate =>
                    "Choose Date" +
                    (selectedDate
                      ? `, selected date is ${selectedDate}`
                      : "")
                  }
                  nextMonthAriaLabel="Next month"
                  placeholder="YYYY/MM/DD"
                  previousMonthAriaLabel="Previous month"
                  todayAriaLabel="Today"
                  onBlur={({detail}) => this.handleSave()}
                />

              </Grid>

              <Grid
                gridDefinition={[{colspan: 6}, {colspan: 4}]}
              >
                <Box variant="h5">
                  Partner Alignment/Commitment:
                </Box>
                <DatePicker
                  readOnly={!this.state.edit}
                  onChange={({detail}) => this.handleChange("RoadmapAlignmentStatus", detail.value)}
                  value={this.state.sessionMgmt.RoadmapAlignmentStatus.completionDate}
                  openCalendarAriaLabel={selectedDate =>
                    "Choose Date" +
                    (selectedDate
                      ? `, selected date is ${selectedDate}`
                      : "")
                  }
                  nextMonthAriaLabel="Next month"
                  placeholder="YYYY/MM/DD"
                  previousMonthAriaLabel="Previous month"
                  todayAriaLabel="Today"
                  onBlur={({detail}) => this.handleSave()}
                />
              </Grid>
            </Container>
          </Box>
      },
      {
        label: "Attendees",
        id: "Attendees",
        content:
          <div>
            <DAttendees props={this.state.sessionMgmt.attendees} name="Attendee"
                        companies={this.WBW_COMPANIES_OPTIONS} events={WBW_Event_TYPE_OPTIONS}
                        handleSave={this.handleSave}
                        updateEventChange={this.props.updateEventChange}
                        edit={this.state.edit}
            />
          </div>
      },
      {
        label: "SFDC Entry",
        id: "SFDCEntry",
        content:
          <div>
            <Input
              autoFocus
              onChange={({ detail }) => this.handleChange("SFDCEntry", detail.value)}
              value={this.state.sessionMgmt.SFDCEntry}
              placeholder = "SFDC Opportunity or Task ID"
            />
          </div>
      },
    ]

    if (sessionOwner){
      tabsContent.push( {
          label: "Permissions",
          id: "Permissions",
          content: <div>
            <Alert>
              <Box>Contributors can add other contributors or individuals with read-only access.
                Just add their Amazon ID to the right permission group.</Box>
              <Box>Example: Add <em>unicorn</em> if <em>unicorn@amazon.com</em> is the email.</Box>
            </Alert>
            <p/>
            <ColumnLayout columns={2}>
              <Container
                header={
                  <Header variant="h3" description="">
                    R/W Access to Partner
                  </Header>
                }
              >
                <DField props={this.state.sessionMgmt.accessRW} name="accessRW"
                        handleSave={this.handleSave} textRows={1}
                        updateEventChange={this.props.updateEventChange}
                        edit={this.state.edit}/>
              </Container>
              <Container
                header={
                  <Header variant="h3" description="">
                    Read-Only Access to Partner
                  </Header>
                }
              >
                <DField props={this.state.sessionMgmt.accessR} name="accessR"
                        handleSave={this.handleSave} textRows={1}
                        updateEventChange={this.props.updateEventChange}
                        edit={this.state.edit}/>
              </Container>
            </ColumnLayout>
          </div>
        });
      tabsContent.push({
        label: "Snapshots",
        id: "Snapshots",
        content:
          <ColumnLayout columns={1} variant="text-grid">
            <Button iconAlign="right"
                    iconName="add-plus"
                    variant="primary"
                    onClick={(detail) => this.handleAddSnapshot(detail)}
                    disabled={!this.state.edit}
            >Create a Snapshot</Button>

            <Table
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No Snapshots</b>
                </Box>
              }
              columnDefinitions={[
                {
                  id: "snapshotID",
                  header: "Snapshot ID",
                  minWidth: "80px",
                  maxWidth: "100px",
                  cell: e => (
                    <Button variant="link" icon="download"
                            onClick={(detail) => this.handleDownloadSnapshot(e.id)}>
                      <Icon name="download"/> {e.id}
                    </Button>
                  ),
                },
                {
                  id: "snapshotDate",
                  header: "Snapshot Date",
                  minWidth: "20px",
                  maxWidth: "30px",
                  cell: e => dateConvert(e.snapshotDate),
                },
              ]}
              items={this.state.sessionMgmt.snapshots}
            >
            </Table>

          </ColumnLayout>
      })
    }

    return (
      <div>
        <a style={{display: "none"}}
           download="download.json"
           href={this.state.fileDownloadUrl}
           ref={e=>this.dofileDownload = e}
        >download it</a>

        <Box padding={{top: "l"}} margin="l">
          <div>
            <SpaceBetween size="m">
              <div>
                <SpaceBetween size="m">
                  <Container
                    header={
                      <Header
                        variant="h3"
                        description=""
                      >
                        Session Management
                      </Header>
                    }
                  >
                    <Box margin="xs">
                      <div>
                        <Container>
                          <ColumnLayout columns={3} variant="text-grid">
                            <SpaceBetween size="l">
                              <ValueWithLabel label="Partner Name"> {sessionName} </ValueWithLabel>
                            </SpaceBetween>
                            <SpaceBetween size="l">
                              <ValueWithLabel label="Partner Type"> {sessionType} </ValueWithLabel>
                            </SpaceBetween>
                            <SpaceBetween size="l">
                              <ValueWithLabel label="Created By"> {createdBy} </ValueWithLabel>
                            </SpaceBetween>
                            <SpaceBetween size="l">
                              <ValueWithLabel
                                label="Date Created"> {new Date(createdOn).toDateString()}
                              </ValueWithLabel>
                            </SpaceBetween>
                            <SpaceBetween size="l">
                              <ValueWithLabel label="Partner Name: "> {partnerName} </ValueWithLabel>
                            </SpaceBetween>
                            <SpaceBetween size="l">
                              <ValueWithLabel label="PDX Assigned: "> {partnerPDX} </ValueWithLabel>
                            </SpaceBetween>
                          </ColumnLayout>
                        </Container>
                        <p/>
                        <Box>
                          <SessionHold sessionHoldHandler={this.sessionHoldHandler} sessionID = {this.props.store.sessionInfo.session.sessionID}/>
                        </Box>

                        <Tabs
                          variant="container"
                          tabs={tabsContent}
                        />
                      </div>
                    </Box>
                  </Container>
                </SpaceBetween>
              </div>
            </SpaceBetween>
          </div>
        </Box>
      </div>
    )
  }
}

const ValueWithLabel = ({label, children}) => (
  <div>
    <Box margin={{bottom: 'xxxs'}} color="text-label">
      {label}
    </Box>
    <div>{children}</div>
  </div>
);

const dateConvert = (value) => {
  const d = new Date(value);
  return d.toLocaleString();
}

const mapStateToProps = (state) => {
  return {store: state};
}

export default connect(mapStateToProps, {
  fetchSession, updateSession, updateSessionMgmt
})(SessionMgmt);