import React from "react";
import "../../styles/servicehomepage.css";
import {
  AppLayout,
  Flashbar,
  Button, Box, Modal, SpaceBetween, Toggle
} from "../../aws-ui-components";

import history from '../../history';
import SessionNav from './SessionNav';
import {connect} from "react-redux";
import {getUser, fetchSession, updateSession} from "../../actions";
import PartnerProfile from './PartnerProfile';
import WBWSessionTools from './wbw/WBWSessionTools';
import OPRSessionTools from "./opr/OPRSessionTools";
import SessionHeader from "./SessionHeader";

class Session extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      uri: props.location.search.substring(1),
      error: false,
      errMsg: null,
      component: PartnerProfile,
      componentProps: {
        errorInComponent: this.errorInComponent,
        updateEventChange: this.updateEventChange,
        saveToDB: this.saveToDB,
        turnOffFlashBar: this.turnOffFlashBar
      },
      notification: [],
      notice: false,
      noticeComp: true,
      unsaved: false,
      modalVisible: false,
      sessionType: '',
    };

    this.props.fetchSession(this.state).then(() => {
      if (this.props.store.sessionInfo !== undefined) {
        if (this.props.store.sessionInfo.session !== undefined) {
          if (this.props.store.sessionInfo.session.sessionType !== undefined){
            this.setState({
              sessionType: this.props.store.sessionInfo.session.sessionType.value,
            })
          }
        }
        if (this.props.store.sessionInfo.response !== undefined)
          if (this.props.store.sessionInfo.response.status === 401) {
            console.log("IN...")
            this.setState({
              modalVisible: true,
              component: 'div'
            })
          } else {
            if (this.props.store.sessionInfo.response.status < 400) {
              this.setState({
                component: PartnerProfile,
                modalVisible: false,
                componentProps: {
                  errorInComponent: this.errorInComponent,
                  updateEventChange: this.updateEventChange,
                  saveToDB: this.saveToDB,
                  turnOffFlashBar: this.turnOffFlashBar
                },
              })
            }
          }
      }
    })
  }

  keepOnPage = (e) => {
    e.preventDefault();
    let message = 'Data not saved!\n Are sure to leave?';
    e.returnValue = message;
    return message;
  }

  updateEventChange = (e) => {
    if (e) {
      window.addEventListener('beforeunload', this.keepOnPage);
      this.setState({
        notification: [
          {
            type: "warning",
            content: <div>Unsaved changes!

            </div>,
            dismissible: false,
            action: <Button
              onClick={(detail) => {
                this.saveToDB();
              }}
            >
              Save
            </Button>

          }
        ],
        unsaved: true,
        notice: true
      })
    } else {
      window.removeEventListener('beforeunload', this.keepOnPage);
      this.setState({notice: false});
      this.setState(...{
        notification: [
          {
            type: "success",
            content: <div>All is well
            </div>,
            dismissible: false,
          }
        ]
      })
    }
  }

  flashTimeOut = () => setTimeout(() => {
    if (!this.state.notice) {
      if (this.state.noticeComp) {
        this.setState({
          notification: [{
            type: "success",
            content: <div>All is well</div>,
            dismissible: false,
          }],
          notice: false
        })
      } else {
        this.setState({
          notification: [],
          notice: false
        })
      }
    }
  }, 1250);

  saveToDB = () => {
    if (this.state.unsaved) {
      this.setState({
        notification: [
          {
            type: "warning",
            loading: true,
            content: "Saving...",
            dismissible: false,
          }
        ]
      })
      if (this.props.store.sessionInfo.session !== undefined)
        if (this.props.store.sessionInfo.session.sessionID !== undefined) {
          this.props.updateSession(this).then((x) => {
            if (x !== undefined && x !== null) {
              if (x.toString().toLowerCase().includes("error")) {
                this.errorInComponent(true, "Error Saving Data!");
                this.setState({
                  notification: [
                    {
                      type: "error",
                      content: "Error Saving data!",
                      dismissible: false,
                    }
                  ]
                })
              } else {
                this.errorInComponent(false, "All is well");
                this.setState({
                  notification: [
                    {
                      type: "success",
                      content: "Data saved",
                      dismissible: false,
                    }
                  ],
                  unsaved: false
                });
                window.removeEventListener('beforeunload', this.keepOnPage);
                this.setState({notice: false});
                this.flashTimeOut();
              }
            } else {
              this.errorInComponent(false, "All is well");
              this.setState({
                notification: [
                  {
                    type: "success",
                    content: "Data saved",
                    dismissible: false,
                  }
                ],
                unsaved: false
              });
              window.removeEventListener('beforeunload', this.keepOnPage);
              this.setState({notice: false});
              this.flashTimeOut();
            }
          });
        }
    }
  }

  errorInComponent = (state, msg) => {
    this.setState({error: state, errMsg: msg})
  }

  turnOffFlashBar = () => {
    // this.setState({
    //   notification: [],
    //   noticeComp: false,
    //   notice: false
    // });
  }

  fetchSessionContent = async (event) => {
    await this.props.fetchSession(this.state).then(() => {
      if (this.props.store.sessionInfo !== undefined)
        if (this.props.store.sessionInfo.response !== undefined)
          if (this.props.store.sessionInfo.response.status === 401) {
            this.setState({
              component: PartnerProfile,
              modalVisible: false,
              componentProps: {}
              })
          }

      if (this.state.noticeComp === true)
        this.setState({
          notification: [{
            type: "success",
            content: <div>All is well</div>,
            dismissible: false,
          }]
        });

      console.log(event.WrappedComponent.name);
      this.setState({
        component: event,
        componentProps: {
          errorInComponent: this.errorInComponent,
          error: this.state.error,
          updateEventChange: this.updateEventChange,
          saveToDB: this.saveToDB,
          turnOffFlashBar: this.turnOffFlashBar,
        }
      })

    })
  }

  onNavChange = async (event) => {
    await this.props.fetchSession(this.state).then(() => {
      if (this.props.store.sessionInfo !== undefined)
        if (this.props.store.sessionInfo.response !== undefined)
          if (this.props.store.sessionInfo.response.status === 401) {
            this.setState({modalVisible: true})
            this.setState({
              component: <div>
                <Modal
                  onDismiss={() => {
                    history.goBack()
                  }}
                  visible={this.state.modalVisible}
                  size="small"
                >
                  <h1>{this.props.store.sessionInfo.response.data.message}</h1>
                </Modal>
              </div>
            })
          }
      if (this.state.noticeComp === true)
        this.setState({
          notification: [{
            type: "success",
            content: <div>All is well</div>,
            dismissible: false,
          }]
        });

      this.setState({
        component: event,
        componentProps: {
          errorInComponent: this.errorInComponent,
          error: this.state.error,
          updateEventChange: this.updateEventChange,
          saveToDB: this.saveToDB,
          turnOffFlashBar: this.turnOffFlashBar,
        }
      })

    })


  }

  getSessionTools = () => {
    switch (this.state.sessionType) {
      case "opr":
        return <OPRSessionTools/>;
        break;
      case "wbw":
        return <WBWSessionTools/>;
        break;
    };
  }


  render() {
    return (
      <AppLayout
        disableContentPaddings={true}
        navigation={<SessionNav navChange={this.onNavChange}/>}
        content={<div>
          <SessionHeader/>
          <Modal
            onDismiss={() => {
              history.goBack()
            }}
            visible={this.state.modalVisible}
            size="small"
          >
            <h2 align="center">{(this.props.store.sessionInfo.response !== undefined)?this.props.store.sessionInfo.response.data.message:""}</h2>
            <p align="center">click "X" to close</p>
          </Modal>
          <Flashbar items={this.state.notification}/>
          {(!this.state.modalVisible)?React.createElement(this.state.component, this.state.componentProps):""}
        </div>}
        navigationOpen={true}
        tools={this.getSessionTools()}
        toolsHide={false}
        notifications={<Flashbar items={this.state.warning}/>}
      />
    );

  }
}

const mapStateToProps = (state) => {
  return {store: state, loading: false};
}

export default connect(mapStateToProps, {
  getUser, fetchSession, updateSession
})(Session);


