export const WBWPreQ = {
  wbw: {
    version: "1.1",
    preQ: [
      {
        component: "tab",
        _uid: "t1",
        label: "Business",
        fields: [
          {
            component: "field_group",
            _uid: "t1_s1",
            label: "Alignment",
            fields: [
              {
                component: "testarea",
                _uid: "t1_s1_q1",
                label: "What is your commitment level from executive leadership to invest in building your AWS business?\n" +
                  " - Do you have an Executive sponsor?\n" +
                  " - What goals has your leadership set forth for your AWS business?\n" +
                  " - Have you defined any strategic iniatitves towards AWS today?",
              },
            ]
          },
          {
            component: "field_group",
            _uid: "t1_s2",
            label: "Your Business",
            fields: [
              {
                component: "testarea",
                _uid: "t1_s2_q1",
                label: "If Andy Jassy were to present about your company and capabilities today at re:invent, what would Andy Jassy say about you today?",
              },
              {
                component: "testarea",
                _uid: "t1_s2_q2",
                label: "Describe your business model with revenues, customer acquisition/retention, and margins/profitability. \n" +
                  " - is it historically sound and are you open to evolving it?\n" +
                  " - Do you work with other Cloud Providers?\n" +
                  "\n" +
                  "Please note, if you would prefer not to put revenue/profit numbers on paper, we can discuss those during the workshop. However, a general overview of your lines of business and what are the lines of business that are successful for you",
              },
              {
                component: "testarea",
                _uid: "t1_s2_q3",
                label: "If you were pitching to your ideal whale customer, what value would you present? Why you?",
              },
              {
                component: "testarea",
                _uid: "t1_s2_q4",
                label: "In terms of deep capabilities now, what are your super powers? What would you like to develop? What truly differentiates you?\n" +
                  "\n" +
                  "Note: A lot of companies says things like 'experts in cloud', 'our people', 'high quality outcomes', 'we listen to what customers tell us and work for customer outcomes'.. We'd like to know what you feel you do better than others here. Great examples would be \"We have built our own intellectual property that automates 70% of most data center migration to AWS from vmware that keeps our cost low while delivering great results for customers\" , \"We're willing to sacrifice short term revenue for long term relationships\",etc",
              },

            ]
          },
          {
            component: "field_group",
            _uid: "t1_s3",
            label: "Your Customers",
            fields: [
              {
                component: "testarea",
                _uid: "t1_s3_q1",
                label: "Who is your customer? \n" +
                  " - Ideal Customers (Most profitable/easiest to capture)\n" +
                  " - Difficult Customers (Least profitable, difficult capture)\n" +
                  " - Commonalities amongst your customers (Verticals, Technologies)\n" +
                  " - How often do you walk away from a Customer because they do not align to your capabilities?\n" +
                  " - How do you see this changing over 3 years?"
              },
            ]
          },

        ]
      },

      {
        component: "tab",
        _uid: "t2",
        label: "People",
        fields: [
          {
            component: "field_group",
            _uid: "t2_s1",
            label: "People",
            fields: [
              {
                component: "testarea",
                _uid: "t2_s1_q1",
                label: "Describe your sales & pre-sales personnel.\n" +
                  " ** Sales \n" +
                  " - Total Sales staff?\n" +
                  " - Dedicated AWS Sales? Sales Overlay?\n" +
                  " - What is the average experience, skils of your sales staff?\n" +
                  " - What type of training does your sales staff get on AWS?\n" +
                  " - What geo's do they cover? (US-SE/NE/Central/West, Canada, LATAM, EMEA,APAC,etc)\n" +
                  " - Is their compensation for AWS solutions/services on par with other lines of business that you offer?\n" +
                  " ** Pre-Sales Technical\n" +
                  " - Do you have dedicated Pre-Sales SA / Sales Engineering?\n" +
                  " - Are they dedicated to AWS?\n" +
                  " - What type of training and certification do they have?\n" +
                  " - What is their experience? \n" +
                  " - What is your have SA/SE ratio to Sales?",
              },
              {
                component: "testarea",
                _uid: "t2_s1_q2",
                label: "Describe your delivery personnel including both project and architect/engineering resources. (certifications, years of experience, seasoned vs. new ratio, areas of depth)",
              },
              {
                component: "testarea",
                _uid: "t2_s1_q4",
                label: "How do you onboard new hires (across roles, orgs)? \n" +
                  "- How do you develop your people across your organization?",
              },
              {
                component: "testarea",
                _uid: "t2_s1_q3",
                label: "How do your salespeople and solution engineers present the value of AWS, and assurance for guiding customers through their cloud journey?" +
                  " - Are your teams comfortable positioning AWS to customers who are not using AWS/Cloud before? Do you prefer to target already on cloud customers?",
              },
            ]
          },
        ]
      },

      {
        component: "tab",
        _uid: "t3",
        label: "Operations",
        fields: [
          {
            component: "field_group",
            _uid: "t3_s1",
            label: "Operations",
            fields: [
              {
                component: "testarea",
                _uid: "t3_s1_q1",
                label: "How is each team/employee encouraged to operate with a culture of innovation and betterment?",
              },
              {
                component: "testarea",
                _uid: "t3_s1_q2",
                label: "Take me through your sales cycle, from targeting to pipeline to marketing to sales motion to delivery to post-sale. \n" +
                  "- What metrics are you tracking? \n" +
                  "- Describe your most recent GTM campaign (type, GF or upsell, internal/external resources, time to plan and execute, results, key metrics)",
              },
              {
                component: "testarea",
                _uid: "t3_s1_q3",
                label: "How long does it take for you to get an SOW together after a customer engagement? \n" +
                  " - Is this consistent across sellers/solution areas?\n" +
                  " - Do you have any automation or tooling to assist with this?\n" +
                  " - At what stage are you calculating a detailed AWS cost analysis through AWS Calculator or Simple Monthly Calculator?",
              },
              {
                component: "testarea",
                _uid: "t3_s1_q4",
                label: "How do you objectively prove you are delivering high quality customer experiences?\n" +
                  " - CSATs, Deliver on Time, Scope Changes,etc",
              },
              {
                component: "testarea",
                _uid: "t3_s1_q5",
                label: "How consistent and predictable are your processes across the customer lifecycle? \n" +
                  "- What processes are automated and/or templated?\n" +
                  "- How do you identify which processes to automate?\n" +
                  "- How do you catalog and evangelize automation in your organization?\n" +
                  "- How much of what you deliver to customers is delivered through automation?",
              },
            ]
          },
        ]
      },

      {
        component: "tab",
        _uid: "t4",
        label: "Governance",
        fields: [
          {
            component: "field_group",
            _uid: "t4_s1",
            label: "Governance",
            fields: [
              {
                component: "testarea",
                _uid: "t4_s1_q1",
                label: "How do you mitigate risks in execution?",
              },
              {
                component: "testarea",
                _uid: "t4_s1_q2",
                label: "What run books and processes have you articulated into your organization? \n" +
                  "- What's your internal repository for policies, procedures, run-books and/or playbooks? \n" +
                  "- Which ISO certifications have you achieved to bring some level of rigor to your organization?",
              },
              {
                component: "testarea",
                _uid: "t4_s1_q3",
                label: "How do you ensure your customers have a complete picture of all their technology - licensing, procurement, utilization and cost?",
              },
              {
                component: "testarea",
                _uid: "t4_s1_q4",
                label: "What kind of design standards do you have in your organization? (HA/FT, Secure Reference Architectures, CFN templates)",
              },
            ]
          },
        ]
      },

      {
        component: "tab",
        _uid: "t5",
        label: "Platform & Security",
        fields: [
          {
            component: "field_group",
            _uid: "t5_s1",
            label: "Platform",
            fields: [
              {
                component: "testarea",
                _uid: "t5_s1_q1",
                label: "How do you integrate Well-Architected into your pre-sales process, when designing a workload for the cloud? \n" +
                  "- How often do you integrate Well-Architected into the delivery process?",
              },
              {
                component: "testarea",
                _uid: "t5_s1_q2",
                label: "What is your strategy towards automating delivery and management for customers?\n" +
                  " - How do you identify new objects for automation?\n" +
                  " - How do you gauge the impact of automation in saved labor hours?\n" +
                  " - Do you have dedicated developers helping to develop your automation?",
              },
            ]
          },
          {
            component: "field_group",
            _uid: "t5_s2",
            label: "Security",
            fields: [
              {
                component: "testarea",
                _uid: "t5_s2_q1",
                label: "If there was a breach of a customer's environment, how would you learn from the breach in order to prevent future ones?\n" +
                  " - If you have experienced this , please elaborate on a specific example",
              },
            ]
          }
          ]
      }
    ]
  }
};
