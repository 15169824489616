import React from 'react';
import {Box, ColumnLayout, Container, Header, Tabs, Textarea,} from "../../../aws-ui-components";
import {connect} from "react-redux";
import {fetchSession, updateSession, updateAddRecomm} from "../../../actions";
import DField from "../resources/form/DField";
import SessionHold from "../resources/form/SessionHold";
import {additionalRecommendations, labels} from "./resources/constants";
import DTable from "../resources/form/DTable";

class OPRAddRecomm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      addRecomm: {
        addrecommendations: [],
      },
      edit: false
    };

    this.additionalRecommendationsTableColumnDefinition = [
      {
        id: "addRecomm",
        header: "Recommendations",
        cell: e => e.value,
        sortingField: "value"
      },
    ];

    if (this.props.store.sessionInfo.session.answers !== undefined && this.props.store.sessionInfo.session.answers !== null) {
      const vals = this.props.store.sessionInfo.session.answers;
      let addRecomm = [];
      if (vals !== undefined && vals !== null) {
        if (vals.addRecomm !== undefined && vals.addRecomm !== null) {
          addRecomm = vals.addRecomm;
          if (addRecomm.addrecommendations !== undefined && addRecomm.addrecommendations !== null)
            this.state.addRecomm.addrecommendations = addRecomm.addrecommendations
        }
      }
    }
  }

  handleSave = (name, values) => {
    switch (name) {
      case "addrecommendations":
        this.state.addRecomm.addrecommendations = [...values];
        break;
    }
    this.props.updateAddRecomm(this).then(async () => {
      this.props.saveToDB();
    });
  }

  handleTextChange = (name, value) => {
    this.setState({addRecomm: {...this.state.addRecomm, [name]: value}});
    this.props.updateEventChange(true);
    this.props.updateAddRecomm(this);
  }

  handleTextSave = () => {
    this.props.updateAddRecomm(this).then(async () => {
      this.props.saveToDB();
    });
  }

  componentWillUnmount() {
    this.props.saveToDB();
  }

  sessionHoldHandler = (e) => {
    this.setState({edit: e})
  }

  render() {


    return (
      <div>
        <SessionHold sessionHoldHandler={this.sessionHoldHandler} sessionID = {this.props.store.sessionInfo.session.sessionID}/>

                <Box margin="l">
                  <div>
                    <ColumnLayout columns={1}>
                      <DTable props={this.state.addRecomm.addrecommendations} name="addrecommendations"
                              handleSave={this.handleSave}
                              updateEventChange={this.props.updateEventChange}
                              columnDefinition={this.additionalRecommendationsTableColumnDefinition}
                              fullList={additionalRecommendations} tableHeader={labels.additionalRecommendationsTableHeader} edit={this.state.edit}/>
                    </ColumnLayout>
                  </div>
                </Box>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {store: state};
}

export default connect(mapStateToProps, {
  updateAddRecomm, fetchSession, updateSession
})(OPRAddRecomm);
