// Action Creator
import PWMTAPI from '../apis/PWMTAPI';
import {Auth} from "aws-amplify";
import axios from 'axios';

// ----- AuthN
export const getUser = state =>
  async dispatch => {
    await Auth.currentAuthenticatedUser().then(
      userData => {
        dispatch(addToUser(userData))
      })
      .catch(err => {
        console.log({err});
      });
  }

const addToUser = userData => ({
  type: 'GET_USER',
  payload: {user: userData}
})

// ------ Session Stuff
export const fetchMySessions = () => async dispatch => {
  await Auth.currentAuthenticatedUser().then(async (data) => {
    await PWMTAPI.get('/sessions', {
      headers: {'Authorization': data.signInUserSession.idToken.jwtToken}
    }).then(
      async (response) => {
        await dispatch({
          type: 'FETCH_MY_SESSIONS',
          payload: {sessions: response.data.Items}
        });
        //await console.log(response);
      }
    ).catch(err => {
      console.log({err});
    });
  })
};


export const searchSessions = (qParam, qValue) => async dispatch => {
  await Auth.currentAuthenticatedUser().then(async (data) => {
    await PWMTAPI.get('/sessions?search_field=' + qParam + "&value=" + qValue, {
      headers: {'Authorization': data.signInUserSession.idToken.jwtToken}
    }).then(
      async (response) => {
        await dispatch({
          type: 'FETCH_SEARCH_SESSIONS',
          payload: {search_sessions: response.data.Items}
        })
      }
    ).catch(err => {
      console.log({err});
    });
  })
};

export const fetchSession = (state) => async dispatch => {
  await Auth.currentAuthenticatedUser().then(async (data) => {
    if (state.uri !== undefined || state.uri.length > 0) {
      await PWMTAPI.get('/sessions/' + state.uri, {headers: {'Authorization': data.signInUserSession.idToken.jwtToken}}).then(
        async (response) => {
          await dispatch({
            type: 'FETCH_SESSION',
            payload: {session: response.data.Item}
          });
        }
      ).catch((error) => {
        if (error.response) {
          console.log(error.response.status);
          dispatch({
            type: 'FETCH_SESSION',
            payload: {response: error.response}
          });
        }
      })

    }
  })
};

export const createSession = state => async dispatch => {
  let er = null;
  await Auth.currentAuthenticatedUser().then(async (data) => {
          await PWMTAPI.post('/sessions',
            {...state}, {headers: {'Authorization': data.signInUserSession.idToken.jwtToken}}).then(
            async (response) => {
              //console.log("in session creation");
              await dispatch({
                type: 'CREATE_SESSION',
                payload: {session: {...state}, response: response}
              });
            })
      }).catch(error => {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          er = error.response.status;
        }
        return er;
      });
    return er;
  };


// --- UPDATE SESSION TO DB
export const updateSession = state => async dispatch => {
  return await Auth.currentAuthenticatedUser().then(async (data) => {
    await PWMTAPI.put('/sessions/' + state.props.store.sessionInfo.session.sessionID, state.props.store.sessionInfo.session, {headers: {'Authorization': data.signInUserSession.idToken.jwtToken}}).then(
      async (response) => {
        return response;
      }
    )
  }).catch(err => {
    return err;
  });
};

// --- update wbw session forms to Redux State
export const updateSessionMgmt = input => async dispatch => {
  dispatch({
    type: 'UPDATE_SESSION_STATE',
    payload: {sessionMgmt: input.state.sessionMgmt}
  });
};

export const updatePreQ = state => async dispatch => {
  dispatch({
    type: 'UPDATE_PREQ',
    payload: {preq: state.state}
  });
};

export const updatePreQUpload = input => async dispatch => {
  //console.log (qes);
  dispatch({
    type: 'UPDATE_PREQ_UPLOAD',
    payload: {preq: {...input.state.preq}}
  });
};

export const updateSWOT = input => async dispatch => {
  dispatch({
    type: 'UPDATE_SWOT',
    payload: {swot: input.state.swot}
  });
};

export const updateACTMI = input => async dispatch => {
  dispatch({
    type: 'UPDATE_ACTMI',
    payload: {actmi: input.state.actmi}
  });
};

export const updateAddRecomm = input => async dispatch => {
  dispatch({
    type: 'UPDATE_ADD_RECOMM',
    payload: {addRecomm: input.state.addRecomm}
  });
};

export const updatePRS = input => async dispatch => {
  dispatch({
    type: 'UPDATE_PRS',
    payload: {prs: input.state.prs}
  });
};

export const updateStr = input => async dispatch => {
  await dispatch({
    type: 'UPDATE_STR',
    payload: {str: input.state.str}
  });
};

export const updateUNI = input => async dispatch => {
  await dispatch({
    type: 'UPDATE_UNI',
    payload: {uni: input.state.uni}
  });
};

export const updatePRFAQ = input => async dispatch => {
  await dispatch({
    type: 'UPDATE_PRFAQ',
    payload: {prfaq: input.state.prfaq}
  });
};

export const updateRecomm = input => async dispatch => {
  await dispatch({
    type: 'UPDATE_RECOMM',
    payload: {recommendations: input.state.recommendations}
  });
};

export const updateOpp = input => async dispatch => {
  await dispatch({
    type: 'UPDATE_OPP',
    payload: {opportunities: input.state.opps}
  });
};

export const updateGoals = input => async dispatch => {
  dispatch({
    type: 'UPDATE_GOALS',
    payload: {goals: input.state.goals}
  });
};

// -- Excel upload state
export const updateExcelUploadState = state => async dispatch => {
  await dispatch({
    type: 'UPDATE_EXCEL_UPLOAD_STATE',
    payload: state
  });
};

// ----- uploads
export const uploadArtifact = state => async dispatch => {
  try {
    await Auth.currentAuthenticatedUser().then(async (data) => {
        await PWMTAPI.post('/sessions/' + state.props.selectedSessionInfo.sessionInfo.session.sessionID + '/uploads/' + decodeURI(state.state.selectedUploadFile.name),
          {type: state.state.selectedUploadFile.type, size: state.state.selectedUploadFile.size},
          {headers: {'Authorization': data.signInUserSession.idToken.jwtToken}}
        ).then(
          async (preSignedURL) => {
            await axios.put(preSignedURL.data.url, state.state.selectedUploadFile, {headers: {'Content-Type': state.state.selectedUploadFile.type}})
              .then(
                async (res) => {
                  await dispatch({
                    type: 'FETCH_UPLOAD_COMPLETE',
                    payload: {uploadStatus: res.status}
                  });
                })
          })
      }
    )
  } catch (e) {
    console.log(e);
  }
};


// ------ get a list of Uploaded files for a session
export const fetchUploadsList = (id) => async dispatch => {
  await Auth.currentAuthenticatedUser().then(async (data) => {
    await PWMTAPI.get('/sessions/' + id + '/uploads', {headers: {'Authorization': data.signInUserSession.idToken.jwtToken}}).then(
      async (response) => {
        await dispatch({
          type: 'FETCH_UPLOAD_FILES_LIST',
          payload: {uploads: response.data}
        })
      }
    )
  })
};

export const deleteUploadFile = (id, file) => async dispatch => {
  await Auth.currentAuthenticatedUser().then(async (data) => {
    await PWMTAPI.delete('/sessions/' + id + '/uploads/' + file, {headers: {'Authorization': data.signInUserSession.idToken.jwtToken}}).then(
      async (response) => {
        //console.log(response);
        //fetchUploadsList(id);
      }
    )
  })
};


// ------ fetch a file
export const fetchFileURL = (id, file) => async dispatch => {
  await Auth.currentAuthenticatedUser().then(async (data) => {
    await PWMTAPI.get('/sessions/' + id + '/uploads/' + file, {headers: {'Authorization': data.signInUserSession.idToken.jwtToken}}).then(
      async (response) => {
        console.log(response.data.url);
        await dispatch({
          type: 'FETCH_FILE_URL',
          payload: {uploads: response.data}
        });
      }
    )
  })
};

// -------- User Profile
export const fetchMyProfile = () => async dispatch => {
  await Auth.currentAuthenticatedUser().then(async (data) => {
    const uid = JSON.parse(data.attributes.identities)[0].userId;
    await PWMTAPI.get('/users/'+uid, {
      headers: {'Authorization': data.signInUserSession.idToken.jwtToken}
    }).then(
      async (response) => {
        await dispatch({
          type: 'FETCH_MY_PROFILE',
          payload: {myProfile: response.data.Item}
        });
      }
    ).catch(err => {
      console.log({err});
    });
  })
};

export const addPartnertoProfile = value => async dispatch => {
  let er = null;
  await Auth.currentAuthenticatedUser().then(async (data) => {
    const uid = JSON.parse(data.attributes.identities)[0].userId;
    const spmsid=value.spmsid
    await PWMTAPI.post('/users/'+uid+'/partners/'+spmsid,
      {...value}, {headers: {'Authorization': data.signInUserSession.idToken.jwtToken}}).then(
      async (response) => {
        await dispatch({
          type: 'ADD_PARTNER',
          payload: {myProfile: response.data.Item}
        });
        //console.log(response);
      })
  }).catch(error => {
    if (error.response) {
      //console.log(error.response.status);
      //console.log(error.response.data);
      er = error.response.status;
    }
    return er;
  });
  return er;
};

export const removePartnerFromProfile = value => async dispatch => {
  let er = null;
  await Auth.currentAuthenticatedUser().then(async (data) => {
    const uid = JSON.parse(data.attributes.identities)[0].userId;
    const spmsid=value.spmsid
    await PWMTAPI.delete('/users/'+uid+'/partners/'+spmsid,
       {headers: {'Authorization': data.signInUserSession.idToken.jwtToken}}).then(
      async (response) => {
        await dispatch({
          type: 'REMOVE_PARTNER',
          payload: {myProfile: response.data.Item}
        });
        await console.log(response);
      })
  }).catch(error => {
    if (error.response) {
      console.log(error.response.status);
      console.log(error.response.data);
      er = error.response.status;
    }
    return er;
  });
  return er;
};


//----  Session Hold
export const CreateSessionHold = sessionID => async dispatch => {
  let status = null;
  await Auth.currentAuthenticatedUser().then(async (data) => {
  //  const uid = JSON.parse(data.attributes.identities)[0].userId;
    await PWMTAPI.post('/sessionhold/'+sessionID,
      {}, // has uid and sessionID
      {headers: {'Authorization': data.signInUserSession.idToken.jwtToken}}).then(
      async (response) => {
        status = {statusCode: response.status, info: response.data};
        await dispatch({
          type: 'SESSION_HOLD',
          payload: {info: response}
        });
      })
  }).catch(error => {
    if (error.response) {
      status = {statusCode: error.response.status, info: error.response.data};
    }
    return status;
  });
  return status;
};

export const RemoveSessionHold = sessionID => async dispatch => {
  //console.log("unhold");
  let status = null;
  await Auth.currentAuthenticatedUser().then(async (data) => {
    const uid = JSON.parse(data.attributes.identities)[0].userId;
    await PWMTAPI.delete('/sessionhold/'+sessionID,
      {headers: {'Authorization': data.signInUserSession.idToken.jwtToken}}).then(
      async (response) => {
        status = {statusCode: response.status, info: response.data};
        await dispatch({
          type: 'SESSION_UNHOLD',
          payload: {info: response}
        });
      })
  }).catch(error => {
    console.log("err");
    if (error.response) {
      console.log(error.response.status);
      console.log(error.response.data);
      status = {statusCode: error.response.status, info: error.response.data};
    }
    return status;
  });
  return status;
};


// -- Selectors (Partner and Sessions)
export const AddSelected = (id,name) => async dispatch => {
   dispatch({
    type: 'ADD_SELECTED',
    payload: {selected: id, selectedName:name}
  });
}

// --- fetch partner data
export const fetchPartnerInfo = (SPMSID) => async dispatch => {
  await Auth.currentAuthenticatedUser().then(async (data) => {
    await PWMTAPI.get('/partners/'+SPMSID, {
      headers: {'Authorization': data.signInUserSession.idToken.jwtToken}
    }).then(
      async (response) => {
        await dispatch({
          type: 'FETCH_PARTNER_INFO',
          payload: response.data
        });
        //await console.log(response);
      }
    ).catch(err => {
      console.log({err});
    });
  })
};