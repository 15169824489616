import React from 'react';
import {Box, Button, ColumnLayout, Container, Grid, Modal, SpaceBetween, Spinner, Table} from "../../../aws-ui-components";
import {connect} from "react-redux";

import {fetchSession} from "../../../actions";
import headerimg from "./resources/images/narrative-header.png";
import OPRNarrativeDoc from "./OPRNarrativeDoc";
import {milestonesLabel} from "./resources/constants";

class OPRNarrative extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      pdfStatus: false
    }
    this.props.turnOffFlashBar();
  }


  render() {

    let prioAndIni = [];
    let milestone1SDPs = [];
    let milestone1Comp = [];
    let milestone1Prog = [];
    let milestone2SDPs = [];
    let milestone2Comp = [];
    let milestone2Prog = [];
    let milestone3SDPs = [];
    let milestone3Comp = [];
    let milestone3Prog = [];
    let benefits = [];
    let addRecomm = [];

    if (this.props.selectedSessionInfo.sessionInfo.session !== undefined) {
      if (this.props.selectedSessionInfo.sessionInfo.session.answers !== undefined) {
        const answers = this.props.selectedSessionInfo.sessionInfo.session.answers;
        if (answers.str !== undefined) {
          if (answers.str.prioini !== undefined) {
            prioAndIni = answers.str.prioini;
          }
        }
        if (answers.addRecomm !== undefined) {
          if (answers.addRecomm.addrecommendations !== undefined) {
            addRecomm = answers.addRecomm.addrecommendations;
          }
        }
        if (answers.actmi !== undefined) {
          if (answers.actmi.recommendationBenefits !== undefined) {
            benefits = answers.actmi.recommendationBenefits;
          }
          if (answers.actmi.milestone1SDPs !== undefined) {
            milestone1SDPs = answers.actmi.milestone1SDPs;
          }
          if (answers.actmi.milestone2SDPs !== undefined) {
            milestone2SDPs = answers.actmi.milestone2SDPs;
          }
          if (answers.actmi.milestone3SDPs !== undefined) {
            milestone3SDPs = answers.actmi.milestone3SDPs;
          }
          if (answers.actmi.milestone1Competencies !== undefined) {
            milestone1Comp = answers.actmi.milestone1Competencies;
          }
          if (answers.actmi.milestone2Competencies !== undefined) {
            milestone2Comp = answers.actmi.milestone2Competencies;
          }
          if (answers.actmi.milestone3Competencies !== undefined) {
            milestone3Comp = answers.actmi.milestone3Competencies;
          }
          if (answers.actmi.milestone1Programs !== undefined) {
            milestone1Prog = answers.actmi.milestone1Programs;
          }
          if (answers.actmi.milestone2Programs !== undefined) {
            milestone2Prog = answers.actmi.milestone2Programs;
          }
          if (answers.actmi.milestone3Programs !== undefined) {
            milestone3Prog = answers.actmi.milestone3Programs;
          }
        }
      }
    }

    const answers = this.props.selectedSessionInfo.sessionInfo.session.answers;
    const divStyle = {
      backgroundImage: `url(${headerimg})`,
      width: '100%',
      backgroundPosition: 'center',
      backgroundRepeat: "no-repeat",
      height: '120px',
      backgroundSize: 'cover'
    };

    return (
      <div>
          <Modal
            visible={this.state.pdfStatus}
            size="small"
          >
            Preparing PDF. Please wait...
            <Spinner size="large" variant="normal"/>
          </Modal>

          <Container
            header={
              <div className="centered" style={divStyle}>
                <Box float="right">
                  <OPRNarrativeDoc/>
                </Box>
                <Box>
                  <li/>
                  <h1 style={{color: "#ffffff", textAlign: "center"}}>
                    Onboarding Partners Roadmap
                  </h1>
                  <p style={{color: "#888888", textAlign: "center"}}> (Recommendations and Roadmap) </p>
                </Box>
              </div>
            }
          >
            <div>
              <ColumnLayout columns={1}>
                <div id="Priorities & Initiatives">
                  <h2>Priorities & Initiatives</h2>
                  <hr/>
                  <Box>
                    {prioAndIni.map((item, index) => (
                        <li key={index.toString()}>{item.value} </li>
                    ))}
                  </Box>
                </div>

                <div id="Activities & Milestones">
                  <h2>Recommended Activities & Milestones </h2>
                  <hr/>
                    <Container header={milestonesLabel.milestone1}>
                      <ColumnLayout borders="horizontal" columns={1}>
                        <div id="milestone1ActivitiesAndMileStones">
                          <NewlineText
                              text={'SDPs: ' + milestone1SDPs.map(item =>  item.value).join(', ')}
                          />
                          <NewlineText
                              text={'Competencies: ' + milestone1Comp.map(item =>  item.value).join(', ')}
                          />
                          <NewlineText
                              text={milestone1Prog.map(item =>  item.value).join('\n')}
                          />
                        </div>
                      </ColumnLayout>
                    </Container>
                  <p/>
                    <Container header={milestonesLabel.milestone2}>
                      <ColumnLayout borders="horizontal" columns={1}>
                        <div id="milestone2ActivitiesAndMileStones">
                          <NewlineText
                              text={'SDPs: ' + milestone2SDPs.map(item =>  item.value).join(', ')}
                          />
                          <NewlineText
                              text={'Competencies: ' + milestone2Comp.map(item =>  item.value).join(', ')}
                          />
                          <NewlineText
                              text={milestone2Prog.map(item =>  item.value).join('\n')}
                          />
                        </div>
                      </ColumnLayout>
                    </Container>
                  <p/>

                    <Container header={milestonesLabel.milestone3}>
                      <ColumnLayout borders="horizontal" columns={1}>
                        <div id="milestone3ActivitiesAndMileStones">
                          <NewlineText
                              text={'SDPs: ' + milestone3SDPs.map(item =>  item.value).join(', ')}
                          />
                          <NewlineText
                              text={'Competencies: ' + milestone3Comp.map(item =>  item.value).join(', ')}
                          />
                          <NewlineText
                              text={milestone3Prog.map(item =>  item.value).join('\n')}
                          />
                        </div>
                      </ColumnLayout>
                    </Container>
                  <p/>
                  <Container header="Benefits">
                    <ColumnLayout borders="horizontal" columns={1}>
                      <div id="benefits">
                        <Box>
                          {benefits.map((item, index) => (
                              <li key={index.toString()}>{item.value} </li>
                          ))}
                        </Box>
                      </div>
                    </ColumnLayout>
                  </Container>

                </div>
                <div id="Additional Recommendations">
                  <h2> Additional Recommendations </h2>
                  <hr/>
                  <Box>
                    {addRecomm.map((item, index) => (
                        <li key={index.toString()}>{item.value} </li>
                    ))}
                  </Box>
                </div>

              </ColumnLayout>
            </div>
          </Container>
        <div id="divBlankLines">
          <p/> <p/>
        </div>
      </div>
    )
  }
}

function NewlineText(props) {
  let newText = "";
  if (props.text !== null && props.text !== "" && props.text !== undefined) {
    const text = props.text;
    if (text.length > 0){
      newText = text.split('\n').map((str, index) => <div key={index.toString()}><Box>{str}</Box><p/></div>);
    }
  }
  return newText;
}

const mapStateToProps = (state) => {
  return {selectedSessionInfo: state};
}

export default connect(mapStateToProps, {
  fetchSession
})(OPRNarrative);